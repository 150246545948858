// import Img from "components/Img/Img";
import React from "react";
import { Link } from 'react-router-dom';
import "./ProductListItem.scss";
import { ProductPrice } from "./ProductPrice";
function ProductListItem({ product, path }) {
    const productName = product === null || product === void 0 ? void 0 : product.name.split('#')[0];
    const productSubName = (product === null || product === void 0 ? void 0 : product.name.split('#')[1]) ? product === null || product === void 0 ? void 0 : product.name.split('#')[1] : null;
    return (React.createElement(Link, { className: "ec-productitem", to: path },
        React.createElement("div", { className: "ec-oval" },
            React.createElement("svg", { className: "svgclip" },
                React.createElement("clipPath", { id: "svgclip-product", clipPathUnits: "objectBoundingBox" },
                    React.createElement("path", { d: "M0.5,1 C0.776,1,1,0.903,1,0.782 C1,0.702,1,0.441,1,0 L0,0 C0,0.463,0,0.724,0,0.782 C0,0.903,0.224,1,0.5,1" }))),
            React.createElement("div", { className: "ec-oval--container" }, !!product.imageUrl &&
                // <Img src={`${product?.imageUrl[0]}`} name={`${product?.name}`} alt={`${product?.name}`} />
                React.createElement("img", { src: `${product === null || product === void 0 ? void 0 : product.imageUrl[0]}`, alt: `${product === null || product === void 0 ? void 0 : product.name}` }))),
        React.createElement("h1", { className: "ec-productitem--title" }, productName),
        React.createElement("h2", { className: "ec-productitem--subtitle" }, productSubName),
        React.createElement("p", { className: "ec-productitem--more" },
            React.createElement("span", null,
                React.createElement(ProductPrice, { product: product }),
                "\u20AC"),
            React.createElement("span", null, "voir le d\u00E9tail"))));
}
export default ProductListItem;
