import React from "react";
import { Angle } from "components/Angle/Angle";
import "./Step.scss";
import { isMobile, isTablet } from "react-device-detect";
export function Step({ disabled, children, title, step, activeStep, setActiveStep }) {
    const isStepActive = step === activeStep;
    return (React.createElement("div", { className: "adresses adress-step" },
        isMobile && !isTablet && React.createElement("span", { className: "step is-hidden-mobile" },
            React.createElement("span", null, step)),
        React.createElement("div", { className: `adresse-item ${isStepActive ? 'open ec-cart--step' : 'ec-cart--step'}` },
            React.createElement("div", { className: "ec-cart--step_header" },
                React.createElement("strong", null,
                    React.createElement("span", null,
                        step,
                        " / 4 "),
                    "- ",
                    title),
                React.createElement(Angle, { disabled: disabled, isActive: isStepActive, setActiveStep: setActiveStep, step: step })),
            React.createElement("div", { className: "adresse-infos ec-cart--step_content" }, children))));
}
