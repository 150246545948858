import React from "react";
import "./Auth.scss";
import Register from '../Register/Register';
import Login from '../Login/Login';
import { Helmet } from "react-helmet";
function Auth() {
    return (React.createElement("div", { className: "auth container ec-container" },
        React.createElement(Helmet, null,
            React.createElement("title", null, "Inscription & Connexion - KUST | La Marque des Coiffeurs pas Comme Les Autres")),
        React.createElement("div", { className: "ec-auth" },
            React.createElement("div", { className: "ec-auth--col" },
                React.createElement(Login, null)),
            React.createElement("div", { className: "ec-auth--col" },
                React.createElement(Register, null)))));
}
export default Auth;
