import Auth from 'containers/Auth/Auth';
import ProductsList from 'containers/Products/List/ProductsList';
import ProductDetail from 'containers/Products/Detail/ProductDetail';
import Recovery from 'containers/Recovery/Recovery';
import Cart from 'containers/Cart/Cart';
import Navigation from 'containers/Account/Navigation/Navigation';
import Home from 'containers/Home/Home';
import Order from 'containers/Order/Order';
import { OrderSuccess } from 'containers/Order/OrderSuccess';
import Content from 'containers/Contents/Contents';
import { Reviews } from 'containers/Reviews/Reviews';
import NotFound from 'containers/NotFound/NotFound';
import ColorChart from 'containers/ColorChart/ColorChart';
import { OrderFailure } from 'containers/Order/OrderFailure';
import Quotation from 'containers/Quotation/Quotation';
import { QuotationSuccess } from 'containers/Quotation/components/QuotationSuccess';
import { QuotationFailure } from 'containers/Quotation/components/QuotationFailure';
export const routes = [{
        protected: true,
        path: "/mon-compte/:page?",
        component: Navigation
    },
    {
        exact: true,
        path: "/nos-produits/:tagSlug/:slug",
        component: ProductDetail
    },
    {
        exact: true,
        path: "/pages/:slug",
        component: Content
    },
    {
        exact: true,
        path: "/nos-produits/:tagSlug",
        component: ProductsList
    },
    {
        exact: true,
        protected: true,
        path: "/panier",
        component: Cart
    },
    {
        // exact: true,
        path: "/commande/succes",
        component: OrderSuccess
    },
    {
        path: "/commande/echec",
        component: OrderFailure
    },
    {
        protected: true,
        exact: true,
        path: "/commande",
        component: Order
    },
    {
        path: '/devis/succes',
        component: QuotationSuccess
    },
    {
        path: '/devis/echec',
        component: QuotationFailure
    },
    {
        protected: true,
        exact: true,
        path: "/devis",
        component: Quotation
    },
    {
        path: "/recuperation",
        component: Recovery
    },
    {
        exact: true,
        path: "/authentification",
        component: Auth
    },
    {
        exact: true,
        path: "/avis",
        component: Reviews,
    },
    {
        exact: true,
        path: "/le-nuancier",
        component: ColorChart
    },
    {
        exact: true,
        path: "/",
        component: Home
    },
    {
        path: "404",
        component: NotFound,
    },
    {
        path: "*",
        component: NotFound,
    },
];
