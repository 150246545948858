import { selectPending, selectErrors, selectLastError, selectIsAuthenticated } from "common";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { verifyCartPromotionAttempt, decrementProductQuantityCart, incrementProductQuantityCart, prevalidateCartAttempt, removeFromCart, fetchUserPromotionAttempt, togglePromotionOpen, resetCart, selectProductToModify, prevalidateCartToQuotationAttempt } from "store/cart/cartActions";
import { selectCart, selectIsPromoOpen } from "store/cart/cartSelectors";
import CartItem from "./components/CartItem/CartItem";
import { CartInfos } from "components/Cart/CartInfos";
import "./Cart.scss";
import { Button, InputError } from "components/Form";
import CartPromo from "./components/CartPromo/CartPromo";
import { Helmet } from "react-helmet";
import { reduceCartProduct } from "services/utils/product.mapper";
import { isMobile, isTablet } from 'react-device-detect';
function Cart() {
    const dispatch = useDispatch();
    const cart = useSelector(selectCart);
    const [cartOpen, setCartOpen] = useState(true);
    const apiError = useSelector(selectLastError('PREVALIDATE_CART'));
    const pending = useSelector(selectPending('PREVALIDATE_CART'));
    const promoApiErrors = useSelector(selectErrors('VERIFY_CART_PROMOTION'));
    const promoPending = useSelector(selectPending('VERIFY_CART_PROMOTION'));
    const isPromoOpen = useSelector(selectIsPromoOpen);
    const isAuthenticated = useSelector(selectIsAuthenticated());
    const prevalidate = () => {
        dispatch(prevalidateCartAttempt(cart));
    };
    const prevalidateToQuotation = () => {
        dispatch(prevalidateCartToQuotationAttempt(cart));
    };
    const verifyPromotion = ({ code }) => {
        dispatch(verifyCartPromotionAttempt(code, cart));
    };
    const togglePromo = (open) => {
        dispatch(togglePromotionOpen(open));
    };
    const emptyCart = () => {
        dispatch(resetCart());
    };
    useEffect(() => {
        if (cart) {
            dispatch(fetchUserPromotionAttempt(cart));
        }
    }, [cart.products]);
    return (React.createElement("div", { className: "ec-container ec-cart" },
        React.createElement(Helmet, null,
            React.createElement("title", null, "Panier - KUST | La Marque des Coiffeurs pas Comme Les Autres")),
        React.createElement("div", { className: "ec-cart--container" },
            cart.products.length > 0 ?
                React.createElement(React.Fragment, null, !isPromoOpen && isAuthenticated
                    ? React.createElement(React.Fragment, null,
                        React.createElement("div", { className: `${!cartOpen ? "ec-cart--summary is-closed" : "ec-cart--summary"}` },
                            isMobile && !isTablet && React.createElement("div", null,
                                React.createElement("button", { className: "spoiler", onClick: () => setCartOpen(!cartOpen) }),
                                React.createElement("p", null, "Total")),
                            React.createElement(CartInfos, { cart: cart },
                                React.createElement(Button, { loading: pending, disabled: !(cart.total > 0), type: "button", onClick: prevalidate, buttonName: "Commander", className: "ec-button ec-button--br" }),
                                React.createElement(InputError, { apiErrors: apiError }),
                                React.createElement(Button, { onClick: () => togglePromo(true), buttonName: "Code promotionnel", className: "ec-nudebutton" }))))
                    :
                        React.createElement("div", { className: "column is-one-quarter black-block" },
                            React.createElement(CartPromo, { apiErrors: promoApiErrors, onSubmit: verifyPromotion, togglePromo: togglePromo })))
                : React.createElement(React.Fragment, null),
            React.createElement("div", { className: "ec-cart--content" },
                React.createElement("div", { className: "content" }, cart.products.length
                    ?
                        React.createElement("div", { className: "content-items" },
                            React.createElement("ul", { className: "ec-cart--content_list" }, cart.products.map((product, index) => {
                                return (React.createElement("li", { key: index },
                                    React.createElement(CartItem, { editable: true, increment: () => dispatch(incrementProductQuantityCart(product)), decrement: () => dispatch(decrementProductQuantityCart(product)), remove: () => dispatch(removeFromCart(product)), product: reduceCartProduct(product), findIndex: () => dispatch(selectProductToModify(index)) })));
                            })))
                    : React.createElement("div", null, "Aucun produit dans le panier"))))));
}
export default Cart;
