import React, { useEffect } from 'react';
import { Switch, Route, useLocation } from "react-router-dom";
import ProtectedRoute from 'shared/components/ProtectedRoute';
import Header from 'containers/Header/Header';
import Footer from 'containers/Footer/Footer';
import './App.scss';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProfileAttempt } from 'store/account/profile/profileActions';
import { selectIsAuthenticated, isBrowser } from 'common';
import { routes } from 'routes';
import ReactGA from 'react-ga';
import { Maintenance } from 'containers/Maintenance/Maintenance';
const BY_PASS_KEY = '2PulseByPass';
function App() {
    var _a, _b;
    const dispatch = useDispatch();
    ReactGA.initialize('UA-69522867-1');
    const isAuthenticated = useSelector(selectIsAuthenticated());
    const { pathname, search } = useLocation();
    const byPass = new URLSearchParams(search).get(BY_PASS_KEY);
    if (isBrowser() && byPass) {
        localStorage.setItem(BY_PASS_KEY, 'true');
    }
    ReactGA.pageview(pathname);
    const getRootClasses = () => {
        return pathname === '/' ? 'root-black-background' : '';
    };
    useEffect(() => {
        if (isAuthenticated)
            dispatch(fetchProfileAttempt());
    }, [isAuthenticated]);
    const isMaintenance = isBrowser()
        ? (`${(_a = window.__env__) === null || _a === void 0 ? void 0 : _a.RAZZLE_ENABLE_MAINTENANCE}` && !localStorage.getItem(BY_PASS_KEY))
        : (`${process.env.RAZZLE_ENABLE_MAINTENANCE}` && !byPass);
    console.log({
        isMaintenance,
        local: isBrowser() ? localStorage.getItem(BY_PASS_KEY) : null,
        envVar: isBrowser()
            ? (`${(_b = window.__env__) === null || _b === void 0 ? void 0 : _b.RAZZLE_ENABLE_MAINTENANCE}`)
            : (`${process.env.RAZZLE_ENABLE_MAINTENANCE}`),
        byPass,
    });
    if (isMaintenance) {
        return (React.createElement(Maintenance, null));
    }
    return (React.createElement("div", { className: getRootClasses() },
        React.createElement("div", { className: "container is-fullhd is-header ssss" },
            React.createElement(Header, null)),
        React.createElement(Switch, null, routes.map((route, index) => {
            return !!route.protected
                ? React.createElement(ProtectedRoute, { key: index, path: route.path },
                    React.createElement(Route, { component: route.component }))
                : React.createElement(Route, { key: index, exact: route.exact || false, path: route.path, component: route.component });
        })),
        React.createElement(Footer, null)));
}
export default App;
