import React, { useEffect } from 'react';
import "./Navigation.scss";
import { Link, useParams } from "react-router-dom";
import Profile from '../Profile/Profile';
import Commandes from '../Commandes/Commandes';
import Adresses from '../Adresses/Adresses';
import Videos from '../Videos/Videos';
import Formations from '../Formations/Formations';
import Documents from '../Documents/Documents';
import { useDispatch, useSelector } from 'react-redux';
import { logoutAttempt } from 'common';
import { useDropdown } from 'shared/hooks/useDropdown';
import { selectProfileInfos } from 'store/account/profile/profileSelectors';
import { fetchDocumentsAttempt } from 'store/account/documents/documentActions';
import { selectDocument } from 'store/account/documents/documentSelectors';
import { fetchOrdersAttempt } from 'store/account/orders/ordersActions';
import { selectOrders } from 'store/account/orders/ordersSelectors';
import Devis from '../Devis/Devis';
import Factures from '../Factures/Factures';
import Credits from '../Credits/Credits';
import { selectCredits } from 'store/account/credits/creditsSelectors';
import { fetchCreditsAttempt } from 'store/account/credits/creditsActions';
var Pages;
(function (Pages) {
    Pages["COMMANDS"] = "commandes";
    Pages["BILLS"] = "factures";
    Pages["VIDEOS"] = "videos";
    Pages["TRAININGS"] = "formations";
    Pages["DOCUMENTS"] = "documents";
    Pages["ADDRESSES"] = "adresses";
    Pages["PROFILE"] = "profile";
    Pages["QUOTATIONS"] = "devis";
    Pages["CREDITS"] = "credits";
})(Pages || (Pages = {}));
function Navigation() {
    const { page } = useParams();
    const { dropdownRef, dropdownActive, setDropdownActive } = useDropdown();
    const dispatch = useDispatch();
    const isPageActive = (value) => {
        return value === page ? 'active' : '';
    };
    const profile = useSelector(selectProfileInfos);
    const documents = useSelector(selectDocument);
    const orders = useSelector(selectOrders);
    const credits = useSelector(selectCredits);
    const showCurrentPage = () => {
        switch (page) {
            case Pages.COMMANDS:
                return React.createElement(Commandes, null);
            case Pages.BILLS:
                return React.createElement(Factures, null);
            case Pages.VIDEOS:
                return React.createElement(Videos, null);
            case Pages.TRAININGS:
                return React.createElement(Formations, null);
            case Pages.DOCUMENTS:
                return React.createElement(Documents, null);
            case Pages.ADDRESSES:
                return React.createElement(Adresses, null);
            case Pages.PROFILE:
                return React.createElement(Profile, null);
            case Pages.QUOTATIONS:
                return React.createElement(Devis, null);
            case Pages.CREDITS:
                return React.createElement(Credits, null);
            default:
                return;
        }
    };
    useEffect(() => {
        dispatch(fetchDocumentsAttempt());
        dispatch(fetchOrdersAttempt());
        dispatch(fetchCreditsAttempt());
    }, [dispatch]);
    return (React.createElement("div", { className: "ec-account ec-container" },
        React.createElement("div", { className: "ec-account--container" },
            React.createElement("div", { className: "ec-account--nav" },
                React.createElement("div", { className: "content" },
                    React.createElement("div", { ref: dropdownRef, className: `ec-dropdown  ${dropdownActive ? 'is-active' : ''}` },
                        React.createElement("div", { className: "ec-dropdown--trigger", onClick: () => setDropdownActive(!dropdownActive) },
                            React.createElement("button", { className: "button", "aria-haspopup": "true", "aria-controls": "dropdown-menu4" },
                                React.createElement("span", null, "Mon compte"),
                                React.createElement("div", { className: "arrow" }))),
                        React.createElement("div", { onClick: () => setDropdownActive(false), className: "ec-dropdown--menu", id: "dropdown-menu4", role: "menu" },
                            React.createElement("div", { className: "ec-dropdown--content" },
                                React.createElement("div", { className: "ec-dropdown--item" },
                                    React.createElement(Link, { to: "/mon-compte/profile", className: isPageActive(Pages.PROFILE) },
                                        React.createElement("strong", null, "Profil"))),
                                React.createElement("div", { className: "ec-dropdown--item" },
                                    React.createElement(Link, { to: "/mon-compte/commandes", className: isPageActive(Pages.COMMANDS) },
                                        React.createElement("strong", null, "Mes Commandes"))),
                                React.createElement("div", { className: "ec-dropdown--item" },
                                    React.createElement(Link, { to: "/mon-compte/factures", className: isPageActive(Pages.BILLS) },
                                        React.createElement("strong", null, "Mes Factures"))),
                                React.createElement("div", { className: "ec-dropdown--item" },
                                    React.createElement(Link, { to: "/mon-compte/adresses", className: isPageActive(Pages.ADDRESSES) },
                                        React.createElement("strong", null, "Mes Adresses"))),
                                React.createElement("div", { className: "ec-dropdown--item" },
                                    React.createElement("div", { onClick: () => dispatch(logoutAttempt()) },
                                        React.createElement("strong", null, "D\u00E9connexion")))))))),
            React.createElement("div", { className: "ec-account--content" }, showCurrentPage()))));
}
export default Navigation;
