export const removeEmptyString = (obj) => {
    for (const [key, value] of Object.entries(obj)) {
        if (typeof value === 'string' && value === '') {
            delete obj[key];
        }
    }
    return obj;
};
export const truncate = (str, nbOfWords) => {
    let strTruncated = str.split(" ").splice(0, nbOfWords).join(" ");
    if (str.length > strTruncated.length)
        strTruncated += '...';
    return strTruncated;
};
export const capitalize = (s) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
};
export const debounce = (func, wait, immediate) => {
    let timeout;
    return (...args) => {
        const later = () => {
            timeout = null;
            if (!immediate) {
                func.apply(this, args);
            }
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) {
            func.apply(this, args);
        }
    };
};
export const sortChoices = (product, property) => {
    return product.choices.reduce((acc, obj) => {
        const key = obj[property];
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
    }, {});
};
export const findFilterName = (filters, choices) => {
    const findFilter = filters.find(filter => { var _a; return filter._id === ((_a = choices[0]) === null || _a === void 0 ? void 0 : _a.filter); });
    return findFilter === null || findFilter === void 0 ? void 0 : findFilter.name;
};
export function slugify(text) {
    const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
    const to = "aaaaaeeeeeiiiiooooouuuunc------";
    const newText = text.split('').map((letter, i) => letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)));
    return newText
        .toString() // Cast to string
        .toLowerCase() // Convert the string to lowercase letters
        .trim() // Remove whitespace from both sides of a string
        .replace('#', '-')
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/&/g, '-y-') // Replace & with 'and'
        .replace(/[^\w\-]+/g, '') // Remove all non-word chars
        .replace(/\-\-+/g, '-'); // Replace multiple - with single -
}
