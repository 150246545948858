import React from "react";
import "./Profile.scss";
import { useDispatch, useSelector } from "react-redux";
import { InputError, Input, Button, Select } from "components/Form";
import { removeEmptyString } from "shared/helpers";
import { selectErrors, Countries, selectPending } from "common";
import { Formik, Form } from "formik";
import { updateProfileAttempt } from "store/account/profile/profileActions";
import { selectProfileInfos, selectProfileUpdated } from "store/account/profile/profileSelectors";
import InputDate from "components/Form/Inputs/InputDate";
import { CheckMarkAnimation } from "components/Animations/CheckMarkAnimation";
function Profile() {
    const dispatch = useDispatch();
    const apiErrors = useSelector(selectErrors('UPDATE_PROFILE'));
    const pending = useSelector(selectPending('UPDATE_PROFILE'));
    const profileUpdated = useSelector(selectProfileUpdated);
    const profile = useSelector(selectProfileInfos);
    const onSubmit = (values) => {
        dispatch(updateProfileAttempt(removeEmptyString(values)));
    };
    return (React.createElement("div", { className: "profile" },
        React.createElement("div", { className: "white-block profile" },
            React.createElement(Formik, { validateOnChange: true, initialValues: {
                    lastname: (profile === null || profile === void 0 ? void 0 : profile.lastname) || '',
                    firstname: (profile === null || profile === void 0 ? void 0 : profile.firstname) || '',
                    salon: (profile === null || profile === void 0 ? void 0 : profile.salon) || '',
                    email: profile === null || profile === void 0 ? void 0 : profile.email,
                    email_confirmation: (profile === null || profile === void 0 ? void 0 : profile.email) || '',
                    siret: profile === null || profile === void 0 ? void 0 : profile.siret,
                    intraCommunityVATNumber: profile === null || profile === void 0 ? void 0 : profile.intraCommunityVATNumber,
                    country: profile === null || profile === void 0 ? void 0 : profile.country,
                    phoneFix: profile === null || profile === void 0 ? void 0 : profile.phoneFix,
                    phone: profile === null || profile === void 0 ? void 0 : profile.phone,
                    gender: profile === null || profile === void 0 ? void 0 : profile.gender,
                    birthday: profile === null || profile === void 0 ? void 0 : profile.birthday
                }, enableReinitialize: true, onSubmit: onSubmit }, ({ values }) => (React.createElement(Form, { noValidate: true, autoComplete: 'off', className: "ec-form" },
                React.createElement("strong", { className: "ec-account--content_sub" }, "Modifiez vos informations ou acc\u00E8s \u00E0 votre profil en enregistrant les champs suivants :"),
                React.createElement(Input, { name: "lastname", label: "Nom", className: "ec-input" }),
                React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.lastname }),
                React.createElement(Input, { name: "firstname", label: "Pr\u00E9nom", className: "ec-input" }),
                React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.firstname }),
                React.createElement(Input, { name: "phone", label: "Tel. Mobile", className: "ec-input" }),
                React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.phone }),
                React.createElement(Input, { name: "phoneFix", label: "Tel. Fixe", className: "ec-input" }),
                React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.phoneFix }),
                React.createElement(InputDate, { name: "birthday", label: "Anniversaire", className: "ec-input" }),
                React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.birthday }),
                React.createElement(Select, { label: "Sexe", defaultLabel: "Ne pr\u00E9f\u00E8re pas pr\u00E9ciser", displayKey: "name", valueKey: "value", name: "gender", className: "ec-input", options: [
                        { name: 'Homme', value: 'homme' },
                        { name: 'Femme', value: 'femme' }
                    ] }),
                values.country === Countries.FRANCE ?
                    React.createElement(React.Fragment, null,
                        React.createElement(Input, { name: "siret", label: "Num\u00E9ro de siret", color: "grey", disabled: true, className: "ec-input" }),
                        React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.siret }))
                    :
                        React.createElement(React.Fragment, null,
                            React.createElement(Input, { name: "intraCommunityVATNumber", label: "Num\u00E9ro de TVA intracommunautaire", disabled: true, color: "grey", className: "ec-input" }),
                            React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.intraCommunityVATNumber })),
                React.createElement(Input, { name: "salon", label: "Nom du salon", className: "ec-input" }),
                React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.salon }),
                React.createElement(Input, { name: "email", label: "E-mail", className: "ec-input" }),
                React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.email }),
                React.createElement(Input, { name: "email_confirmation", label: "Confirmation email", className: "ec-input" }),
                React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.email_confirmation }),
                React.createElement(Input, { type: "password", name: "password", label: "Mot de passe", className: "ec-input" }),
                React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.password }),
                React.createElement(Input, { type: "password", name: "password_confirmation", label: "Confirmation du mot de passe", className: "ec-input" }),
                React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.password_confirmation }),
                React.createElement(Button, { loading: pending, buttonName: "Enregistrer", className: "ec-button ec-button--br" }))))),
        React.createElement(CheckMarkAnimation, { message: "La modification de vos donn\u00E9es a bien \u00E9t\u00E9 enregistr\u00E9e.", isActive: profileUpdated })));
}
export default Profile;
