import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./Footer.scss";
function Footer() {
    const { pathname } = useLocation();
    const getFooterClasses = () => {
        return pathname === '/' ? 'footer-container white-background' : 'footer-container';
    };
    function getYear() {
        return new Date().getFullYear();
    }
    return (React.createElement("footer", { className: `ec-footer ${getFooterClasses()}` },
        React.createElement("ul", { className: "ec-footer--menu" },
            React.createElement("li", null,
                React.createElement(Link, { className: "ec-footer--link", to: "/" }, "Loyance")),
            React.createElement("li", null,
                React.createElement(Link, { className: "ec-footer--link", to: "/nos-produits" }, "Pinceaux")),
            React.createElement("li", null,
                React.createElement(Link, { className: "ec-footer--link", to: "/nos-produits" }, "Accessoires")),
            React.createElement("li", null,
                React.createElement(Link, { className: "ec-footer--link", to: "/nos-produits" }, "Consommables")),
            React.createElement("li", null,
                React.createElement(Link, { className: "ec-footer--link", to: "/pages/cgv" }, "CGV/CGU")),
            React.createElement("li", null,
                React.createElement(Link, { className: "ec-footer--link", to: "/pages/mentions-legales" }, "Mentions")),
            React.createElement("li", null,
                React.createElement(Link, { className: "ec-footer--link", to: "/pages/confidentialite" }, "Confidentialit\u00E9"))),
        React.createElement("div", { className: "ec-footer--desc" }, "Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilis\u00E9e \u00E0 titre provisoire pour calibrer une mise en page, le texte d\u00E9finitif venant remplacer le faux-texte d\u00E8s qu'il est pr\u00EAt ou que la mise en page est achev\u00E9e. G\u00E9n\u00E9ralement, on utilise un texte en faux latin, le Lorem ipsum ou Lipsum."),
        React.createElement("img", { className: "ec-footer--logo", src: "/assets/logo-loyance--rose.svg", alt: "logo loyance rose" })));
}
export default Footer;
