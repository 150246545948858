import { logoutAttempt } from 'common';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { downloadDocumentAttempt } from 'store/account/documents/documentActions';
import { selectDocument } from 'store/account/documents/documentSelectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import "./Documents.scss";
import { Button } from 'components/Form';
function Documents() {
    const dispatch = useDispatch();
    const documents = useSelector(selectDocument);
    return (React.createElement("div", { className: "profile" },
        React.createElement("div", { className: "before-white" },
            React.createElement("div", null,
                React.createElement("h1", null, "Vos documents"),
                React.createElement("p", null, "Bienvenue dans votre espace personnel KUST !")),
            React.createElement(Button, { buttonName: "D\u00E9connexion", onClick: () => dispatch(logoutAttempt()), className: "black-white" })),
        React.createElement("div", { className: "white-block" },
            React.createElement("div", { className: "documents container" }, (documents === null || documents === void 0 ? void 0 : documents.length) > 0 &&
                React.createElement("div", null, documents.map((document) => React.createElement("div", { className: "document-item", key: document === null || document === void 0 ? void 0 : document._id },
                    React.createElement("div", null,
                        React.createElement("strong", null, document.name),
                        React.createElement("p", null, document.description),
                        React.createElement("p", { className: "format" }, "Format .pdf")),
                    React.createElement("div", null,
                        React.createElement("button", { onClick: () => dispatch(downloadDocumentAttempt(document._id)) },
                            "Voir ",
                            React.createElement(FontAwesomeIcon, { icon: faEye }))))))),
            (documents === null || documents === void 0 ? void 0 : documents.length) === 0 &&
                React.createElement("div", null, "Il n'y a pas de documents disponibles pour le moment."))));
}
export default Documents;
