import { Button, Input, InputError } from "components/Form";
import { Form, Formik } from "formik";
import React from "react";
import './CartPromo.scss';
function CartPromo({ onSubmit, apiErrors, togglePromo }) {
    return (React.createElement(Formik, { validateOnChange: true, initialValues: {
            code: '',
        }, enableReinitialize: true, onSubmit: onSubmit },
        React.createElement(Form, { noValidate: true, autoComplete: 'off', className: "cart-promo" },
            React.createElement("div", { className: "summary-infos" },
                React.createElement("h1", null, "Code promo"),
                React.createElement(Input, { name: "code", className: "grey label-left", placeholder: "Votre code..." }),
                React.createElement(InputError, { apiErrors: (apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.code) || (apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.message) }),
                React.createElement(Button, { type: "submit", buttonName: "Valider", className: "orange" }),
                React.createElement(Button, { type: "button", onClick: () => togglePromo(false), buttonName: "Annuler et revenir au panier", className: "transparent" })))));
}
export default CartPromo;
