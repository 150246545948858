import { convertToEuros, ProductType } from "common";
import React from "react";
export function ProductPrice({ product }) {
    const getDefaultProductPrice = () => {
        var _a, _b;
        switch (product.type) {
            case ProductType.CHOICE_PRODUCT:
            case ProductType.SIMPLE_PRODUCT:
                return product.price;
            case ProductType.SIMPLE_GROUP:
            case ProductType.CHOICE_GROUP:
            case ProductType.SCALE_GROUP:
                return (_b = (_a = product.elements) === null || _a === void 0 ? void 0 : _a.reduce((prev, curr) => {
                    return prev.price < curr.price ? prev : curr;
                })) === null || _b === void 0 ? void 0 : _b.price;
        }
    };
    // const getTotalProductPrice = (): number => {
    //     switch (productCart.type) {
    //         case ProductType.CHOICE_PRODUCT:
    //             const choicesQuantity = !productCart.restricted ? getChoicesQuantity(productCart) : 1;
    //             return productCart.price * choicesQuantity * productCart.quantity;
    //         case ProductType.SIMPLE_PRODUCT:
    //             return productCart.price * productCart.quantity;
    //         case ProductType.SIMPLE_GROUP:
    //         case ProductType.CHOICE_GROUP:
    //             return productCart.element?.price! * productCart.quantity;
    //         case ProductType.SCALE_GROUP:
    //             const choicesScaleQuantity = getChoicesQuantity(productCart);
    //             return productCart.element?.price! * choicesScaleQuantity * productCart.quantity;
    //     }
    // }
    return (React.createElement(React.Fragment, null, `${convertToEuros(getDefaultProductPrice())}`));
}
