import React from "react";
import { Helmet } from "react-helmet";
import { useYotpoRefresh } from "shared/hooks/useYotpoRefresh";
import './Reviews.scss';
export function Reviews() {
    useYotpoRefresh();
    return (React.createElement("div", { className: "container reviews" },
        React.createElement(Helmet, null,
            React.createElement("title", null, "Avis - KUST | La Marque des Coiffeurs pas Comme Les Autres")),
        React.createElement("h1", null, "LES AVIS"),
        React.createElement("h2", null, "De nos clients professionnels certifi\u00E9s"),
        React.createElement("p", { className: "description" }, "Vous aussi avez entendu parler de la coloration KUST ? De ses patines\u00A0? ou encore de son lissage br\u00E9silien ? Vous souhaitez savoir ce que les autres coiffeurs en pensent\u00A0? Rien de mieux que de lire leurs avis. Et de se faire une id\u00E9e pr\u00E9cise sur la qualit\u00E9 de nos produits !"),
        React.createElement("div", { id: "y-badges", "data-lang": "{$yotpoLanguage|escape:'htmlall':'UTF-8'}", className: "yotpo yotpo-badge badge-init" }, "\u00A0"),
        React.createElement("div", { id: 'yotpo-testimonials-custom-tab' })));
}
