import { Button } from "components/Form";
import React, { useContext } from "react";
import { OrderContext } from "../Order";
export function StepButton({ title = "Etape suivante", hidden = false, loading, disabled, onClick }) {
    const context = useContext(OrderContext);
    const getButton = () => {
        return !hidden && React.createElement(Button, { type: "button", className: "ec-button ec-button--tertiary ec-button--step", loading: loading, disabled: disabled, onClick: onClick, buttonName: title });
    };
    return (React.createElement(React.Fragment, null, getButton()));
}
