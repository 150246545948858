import { ProductType, selectGenericErrors } from "common";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductsAttempt } from "store/products/productActions";
import { selectProductsFiltered } from "store/products/productSelectors";
import ProductListItem from "../components/ProductListItem";
import { ProductsFilters } from "../Filter/ProductsFilters";
import "./ProductsList.scss";
import { useParams } from "react-router-dom";
import { selectTags } from "store/tags/tagSelectors";
import { slugify } from "shared/helpers";
function ProductsList() {
    const dispatch = useDispatch();
    const genericError = useSelector(selectGenericErrors('FETCH_PRODUCTS'));
    const products = useSelector(selectProductsFiltered);
    const tags = useSelector(selectTags);
    const { tagSlug } = useParams();
    const selectedTag = tags.find((tag) => {
        return slugify(tag.name) === tagSlug;
    });
    useEffect(() => {
        var _a, _b, _c;
        try {
            if (typeof ((_a = window) === null || _a === void 0 ? void 0 : _a.yotpo) !== "undefined") {
                (_c = (_b = window) === null || _b === void 0 ? void 0 : _b.yotpo) === null || _c === void 0 ? void 0 : _c.initWidgets();
            }
        }
        catch (err) {
            console.log('initWidget - error', err);
        }
    }, []);
    useEffect(() => {
        dispatch(fetchProductsAttempt());
    }, [dispatch]);
    return (React.createElement("section", { className: "ec-container ec-list" },
        React.createElement("div", { className: "ec-filters" },
            React.createElement(ProductsFilters, null)),
        React.createElement(Helmet, null,
            React.createElement("title", null, "Nos produits - KUST | La Marque des Coiffeurs pas Comme Les Autres")),
        React.createElement("header", { className: "ec-list--header" },
            React.createElement("h1", { className: "ec-list--title" }, selectedTag === null || selectedTag === void 0 ? void 0 : selectedTag.name),
            React.createElement("strong", { className: "ec-list--desc" }, selectedTag === null || selectedTag === void 0 ? void 0 : selectedTag.description)),
        (products === null || products === void 0 ? void 0 : products.length) > 0 &&
            React.createElement("section", { className: "ec-grid" }, products.filter((product) => product.tag === (selectedTag === null || selectedTag === void 0 ? void 0 : selectedTag._id) && product.type === ProductType.SIMPLE_PRODUCT).map((product) => React.createElement("article", { className: "ec-grid--item", key: product === null || product === void 0 ? void 0 : product._id },
                React.createElement(ProductListItem, { path: `/nos-produits/le-tag/${product.slug}`, product: product })))),
        !!genericError && React.createElement("h1", null, genericError)));
}
export default ProductsList;
