import { push } from "connected-react-router";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectOrder } from "store/order/orderSelectors";
import { Link } from "react-router-dom";
import "./OrderSuccess.scss";
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
export function OrderSuccess() {
    const dispatch = useDispatch();
    const order = useSelector(selectOrder);
    const items = order === null || order === void 0 ? void 0 : order.products.map((product) => {
        return {
            id: product._id,
            name: product.name,
            sku: product.code,
            price: (product.priceVat / 10),
            quantity: product.quantity,
        };
    });
    ReactGA.plugin.require('ecommerce');
    ReactGA.plugin.execute('ecommerce', 'addItem', items);
    ReactGA.plugin.execute('ecommerce', 'addTransaction', {
        id: order === null || order === void 0 ? void 0 : order.orderId,
        revenue: (order === null || order === void 0 ? void 0 : order.totalVatWithPromo) ? order.totalVatWithPromo / 10 : 0,
    });
    ReactGA.plugin.execute('ecommerce', 'send', '');
    ReactGA.plugin.execute('ecommerce', 'clear', '');
    useEffect(() => {
        if (!order)
            dispatch(push('/'));
    }, [order]);
    return (React.createElement("div", { className: "ec-container ec-success ec-background" }, order &&
        React.createElement("div", { className: "ec-success--container" },
            React.createElement(Helmet, null,
                React.createElement("title", null, "F\u00E9licitation - KUST | La Marque des Coiffeurs pas Comme Les Autres")),
            React.createElement("div", { className: "ec-success--text" },
                React.createElement("h1", null,
                    "Un grand ",
                    React.createElement("b", null, "merci")),
                React.createElement("p", null,
                    "Votre commande a \u00E9t\u00E9 pass\u00E9e avec succ\u00E8s et nous avons h\u00E2te que vous preniez vos produits Loyance en main. Nous sommes disponibles pour nos incroyables clients presque jour et nuit. N\u2019h\u00E9sitez donc pas \u00E0 la moindre question ou remarque \u00E0 ",
                    React.createElement(Link, { to: "/pages/nous-contacter", className: "contact" }, "nous contacter"),
                    " !"),
                React.createElement("div", { className: "ec-success--text_links" },
                    React.createElement(Link, { to: "/pages/nous-contacter", className: "ec-button ec-button--bl" }, "Instagram"),
                    React.createElement(Link, { to: "/mon-compte/commandes", className: "ec-nudebutton" }, "Mes commandes"))))));
}
