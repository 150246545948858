import { getChoicesQuantity, getFreeChoices, getFreeIncluded, haveFreeProducts, haveIncludedProducts, isFreeChoicesStockValid, isGroupProduct, ProductType, convertToEuros } from "common";
import { Decrement, Increment } from "components/Quantity";
import React, { useEffect } from "react";
import Img from "components/Img/Img";
import "./CartItem.scss";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductsAttempt } from "store/products/productActions";
import { selectProductsWithLeaves } from "store/products/productSelectors";
function CartItem({ increment, decrement, remove, editable, product, findIndex }) {
    var _a, _b, _c, _d, _e, _f;
    const dispatch = useDispatch();
    const products = useSelector(selectProductsWithLeaves);
    useEffect(() => {
        dispatch(fetchProductsAttempt());
    }, [dispatch]);
    const productName = product === null || product === void 0 ? void 0 : product.name.split('#')[0];
    const productSubName = (product === null || product === void 0 ? void 0 : product.name.split('#')[1]) ? product === null || product === void 0 ? void 0 : product.name.split('#')[1] : null;
    return (React.createElement("div", { className: "ec-cartitem" },
        React.createElement("div", { className: "ec-cartitem--visual" },
            React.createElement("div", { className: "ec-oval" },
                React.createElement("div", { className: "ec-oval--container" }, ((_a = product === null || product === void 0 ? void 0 : product.imageUrl) === null || _a === void 0 ? void 0 : _a.length) > 0 &&
                    React.createElement(Img, { src: `${product === null || product === void 0 ? void 0 : product.imageUrl[0]}`, name: `${product.name}` }))),
            React.createElement("svg", { className: "svgclip" },
                React.createElement("clipPath", { id: "svgclip-product", clipPathUnits: "objectBoundingBox" },
                    React.createElement("path", { d: "M0.5,1 C0.776,1,1,0.903,1,0.782 C1,0.702,1,0.441,1,0 L0,0 C0,0.463,0,0.724,0,0.782 C0,0.903,0.224,1,0.5,1" })))),
        React.createElement("div", { className: "ec-cartitem--data" },
            React.createElement("div", { className: "ec-cartitem--name" },
                React.createElement("strong", null, productName),
                React.createElement("small", null, productSubName),
                isGroupProduct(product) && React.createElement("small", null, (_b = product === null || product === void 0 ? void 0 : product.element) === null || _b === void 0 ? void 0 : _b.name)),
            editable &&
                React.createElement("div", { className: "ec-cartitem--qty" },
                    (product === null || product === void 0 ? void 0 : product.quantity) <= 1 && React.createElement("button", { className: "ec-roundbutton ec-roundbutton--delete", type: "button", onClick: () => remove(product) }, "\u00A0"),
                    (product === null || product === void 0 ? void 0 : product.quantity) > 1 && React.createElement(Decrement, { decrement: () => decrement(product) }),
                    React.createElement("span", null, product === null || product === void 0 ? void 0 : product.quantity),
                    (product === null || product === void 0 ? void 0 : product.type) !== ProductType.SIMPLE_PRODUCT && React.createElement("span", { onClick: findIndex },
                        React.createElement(Link, { to: `/nos-produits/${product === null || product === void 0 ? void 0 : product.slug}` }, "Modifier")),
                    (product === null || product === void 0 ? void 0 : product.type) === ProductType.SIMPLE_PRODUCT && React.createElement(Increment, { increment: () => increment(product) })),
            !editable &&
                React.createElement("div", { className: "ec-cartitem--qty" },
                    React.createElement("span", null, product === null || product === void 0 ? void 0 : product.quantity)),
            (product === null || product === void 0 ? void 0 : product.choices.length) > 0 &&
                React.createElement("div", { className: "ec-cartitem--qty" }, (_c = product === null || product === void 0 ? void 0 : product.choices) === null || _c === void 0 ? void 0 : _c.map((choice, index) => {
                    return React.createElement("div", { className: "choice-item column is-2-desktop is-3-mobile", key: index }, choice === null || choice === void 0 ? void 0 :
                        choice.name,
                        React.createElement("span", null, choice === null || choice === void 0 ? void 0 : choice.quantity));
                })),
            React.createElement("div", { className: "ec-cartitem--price" }, isGroupProduct(product) ?
                React.createElement(React.Fragment, null,
                    React.createElement("span", null,
                        convertToEuros((_d = product === null || product === void 0 ? void 0 : product.element) === null || _d === void 0 ? void 0 : _d.price),
                        "\u20AC HT"))
                :
                    React.createElement(React.Fragment, null,
                        React.createElement("span", null,
                            (convertToEuros(product.price
                                * (product.type === ProductType.CHOICE_PRODUCT || product.type === ProductType.SCALE_GROUP && !product.restricted ? getChoicesQuantity(product) : 1)
                                * product.quantity)),
                            "\u20AC HT"))),
            React.createElement("table", null,
                haveFreeProducts(product) && ((_e = getFreeChoices(product)) === null || _e === void 0 ? void 0 : _e.ids.map((choice, index) => {
                    return React.createElement("tr", { key: index }, choice.quantity > 0 && React.createElement(React.Fragment, null,
                        React.createElement("td", null, choice.name),
                        " ",
                        React.createElement("td", null,
                            " x ",
                            !isFreeChoicesStockValid(choice, products) ? choice.quantity + ' dont ' + (choice.quantity - (choice.stock > 0 ? choice.stock : 0)) + ' reliquat(s)' : choice.quantity),
                        React.createElement("td", null,
                            React.createElement("strong", null, "OFFERT"))));
                })),
                haveIncludedProducts(product) && ((_f = getFreeIncluded(product)) === null || _f === void 0 ? void 0 : _f.ids.map((choice, index) => {
                    return React.createElement("tr", { key: index },
                        React.createElement("td", null, choice.name),
                        " ",
                        React.createElement("td", null,
                            " x ",
                            !isFreeChoicesStockValid(choice, products) ? choice.quantity + ' dont ' + (choice.quantity - (choice.stock > 0 ? choice.stock : 0)) + ' reliquat(s)' : choice.quantity),
                        React.createElement("td", null,
                            React.createElement("strong", null, "INCLUS")));
                }))))));
}
export default CartItem;
