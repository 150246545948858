import { ProductType, selectIsAuthenticated, } from "common";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchProductAttempt } from "store/products/productActions";
import { selectProduct } from "store/products/productSelectors";
import { Helmet } from "react-helmet";
import { Formik } from "formik";
import { addToCart, editToCart, selectProductToModify } from "store/cart/cartActions";
import "./ProductDetail.scss";
import useModal from "shared/hooks/useModal";
import { truncate } from "shared/helpers";
import { selectCartProducts, selectProductAddedToCart, selectProductIndexToModify } from "store/cart/cartSelectors";
import { CheckMarkAnimation } from "components/Animations/CheckMarkAnimation";
import { ProductForm } from "../Form/ProductForm";
import Carousel from "../components/Carousel";
import CarouselSlide from "../components/CarouselSlide";
import { ProductPrice } from "../components/ProductPrice";
function ProductDetail() {
    const [MODAL_ID] = useState('freeProductsModal');
    const [reviewId, setReviewId] = useState('');
    const { slug } = useParams();
    const dispatch = useDispatch();
    const { toggleModal, closeModal } = useModal();
    const productAddedToCart = useSelector(selectProductAddedToCart);
    const isAuthenticated = useSelector(selectIsAuthenticated());
    const productIndexToModify = useSelector(selectProductIndexToModify);
    const products = useSelector(selectCartProducts);
    const selectedProductToModify = products[productIndexToModify];
    const product = useSelector(selectProduct(slug));
    useEffect(() => {
        dispatch(fetchProductAttempt(slug));
        return () => {
            dispatch(selectProductToModify(null));
        };
    }, [dispatch, slug, isAuthenticated]);
    useEffect(() => {
        setReviewId(product === null || product === void 0 ? void 0 : product.reviewId);
    }, [product]);
    const onSubmit = (cartProduct) => {
        !!selectedProductToModify
            ? dispatch(editToCart(Object.assign(Object.assign({}, cartProduct), { quantity: +cartProduct.quantity })))
            : dispatch(addToCart(Object.assign(Object.assign({}, cartProduct), { quantity: +cartProduct.quantity })));
        closeModal(MODAL_ID);
    };
    const productName = product === null || product === void 0 ? void 0 : product.name.split('#')[0];
    const productSubName = (product === null || product === void 0 ? void 0 : product.name.split('#')[1]) ? product === null || product === void 0 ? void 0 : product.name.split('#')[1] : null;
    return (React.createElement("div", { className: "container product-detail ec-product" }, !!product && (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("title", null,
                productName,
                " - ",
                productSubName),
            React.createElement("meta", { name: "description", content: truncate(`${product === null || product === void 0 ? void 0 : product.description}`, 150) }),
            React.createElement("body", { className: "ec-product--bg" }),
            React.createElement("script", { type: "application/ld+json" }, `{
              "@context": "https://schema.org/",
              "@type": "Product",
              "name": "${productName}",
              "image": ["${product.imageUrl[0]}"],
              "description": "${product.description}",
              "sku": "${product.code}",
              "brand": {
                "@type": "Brand",
                "name": "KUST"
              }
            }`)),
        React.createElement("section", { className: "ec-product--intro", id: "anchor" },
            React.createElement("div", { className: "ec-product--visual" },
                product.imageUrl && product.imageUrl.length === 1 ?
                    React.createElement("div", { className: "ec-oval" },
                        React.createElement("div", { className: "ec-oval--container" },
                            React.createElement("img", { src: product.imageUrl[0], alt: product.name })))
                    :
                        React.createElement(Carousel, null, product === null || product === void 0 ? void 0 : product.imageUrl.map((img) => (React.createElement(CarouselSlide, { key: img, imageUrl: img })))),
                React.createElement("svg", { className: "svgclip" },
                    React.createElement("clipPath", { id: "svgclip-product", clipPathUnits: "objectBoundingBox" },
                        React.createElement("path", { d: "M0.5,1 C0.776,1,1,0.903,1,0.782 C1,0.702,1,0.441,1,0 L0,0 C0,0.463,0,0.724,0,0.782 C0,0.903,0.224,1,0.5,1" })))),
            React.createElement("div", { className: "ec-product--data" },
                React.createElement("header", null,
                    React.createElement("h1", { className: "ec-product--title" }, productName),
                    React.createElement("h2", { className: "ec-product--subtitle" }, productSubName)),
                React.createElement("div", { className: "ec-product--desc", dangerouslySetInnerHTML: { __html: product.description } }),
                React.createElement("div", { className: "ec-product--price" },
                    React.createElement(ProductPrice, { product: product }),
                    "\u20AC ",
                    React.createElement("small", null, "H.T.")),
                React.createElement(Formik, { validateOnChange: true, initialValues: Object.assign(Object.assign({}, product), { quantity: product.type === ProductType.SIMPLE_PRODUCT ? 0 : 1, element: !!selectedProductToModify ? selectedProductToModify === null || selectedProductToModify === void 0 ? void 0 : selectedProductToModify.element : null, choices: !!selectedProductToModify ? selectedProductToModify === null || selectedProductToModify === void 0 ? void 0 : selectedProductToModify.choices : product === null || product === void 0 ? void 0 : product.choices }), enableReinitialize: true, onSubmit: (values, actions) => {
                        onSubmit(values);
                        actions.resetForm();
                    } },
                    React.createElement(ProductForm, { product: product, setReviewId: setReviewId, modalId: MODAL_ID, toggleModal: toggleModal })),
                React.createElement(CheckMarkAnimation, { message: "Produit(s) ajout\u00E9(s) \u00E0 votre panier !", isActive: productAddedToCart }))),
        React.createElement("section", { className: "ec-product--bonus", id: "bonus" }, !!product.informations &&
            React.createElement("div", { className: "ec-product--bonus_container" },
                React.createElement("h1", null, "Fabrication fran\u00E7aise depuis 1779"),
                React.createElement("strong", null, "Depuis 1779, sept g\u00E9n\u00E9rations se succ\u00E8dent \u00E0 Saint-Brieuc en Bretagne pour fabriquer \u00E0 la main des pinceaux d'une qualit\u00E9 irr\u00E9prochable."),
                React.createElement("img", { src: "../assets/pinceau-coloriste-loyance.png", alt: "pinceau pour coloriste coiffeur fabrication fran\u00E7aise" }),
                React.createElement("ul", null,
                    React.createElement("li", null,
                        React.createElement("strong", null, "Ergonomie"),
                        "Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilis\u00E9e \u00E0 titre provisoire."),
                    React.createElement("li", null,
                        React.createElement("strong", null, "Durabilit\u00E9"),
                        "Texte d\u00E9finitif venant remplacer le faux-texte d\u00E8s qu'il est pr\u00EAt ou que la mise en page."),
                    React.createElement("li", null,
                        React.createElement("strong", null, "Technologie"),
                        "Il est pr\u00EAt ou que la mise en page est achev\u00E9e. On utilise un texte en faux latin.")),
                React.createElement("a", { href: "#anchor", className: "ec-button ec-button--b ec-button--invert" }, "Commander"),
                React.createElement("svg", { className: "svgclip" },
                    React.createElement("clipPath", { id: "svgclip-section", clipPathUnits: "objectBoundingBox" },
                        React.createElement("path", { d: "M0,0.028 L0.5,0.001 C0.5,0.001,0.5,0.001,0.5,0.001 L1,0.028 L1,0.028 L1,0.973 L0.5,1 C0.5,1,0.5,1,0.5,1 L0,0.973 L0,0.973 L0,0.028" }))),
                React.createElement("svg", { className: "svgclip" },
                    React.createElement("clipPath", { id: "svgclip-section--xs", clipPathUnits: "objectBoundingBox" },
                        React.createElement("path", { d: "M0,0.009 L0.487,0 C0.487,0,0.487,0,0.487,0 L1,0.009 L1,0.009 L1,0.991 L0.487,1 C0.487,1,0.487,1,0.487,1 L0,0.991 L0,0.991 L0,0.009" })))))))));
}
export default ProductDetail;
