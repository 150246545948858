import React from "react";
import { Days } from "common";
import { capitalize } from "shared/helpers";
import { FieldArray } from "formik";
export function ShippingDaysOff() {
    return (React.createElement("div", null,
        React.createElement("span", { className: "ec-cart--step_sub" },
            "2. Quels sont les jours de ",
            React.createElement("b", null, "fermeture"),
            " de votre salon ?"),
        React.createElement("div", { className: "days-off-list ec-cart--step_days" },
            React.createElement(FieldArray, { name: "daysOff" }, ({ remove, push, form }) => (Object.values(Days).map((day, index) => {
                return React.createElement("div", { key: index, className: "ec-radio ec-check" },
                    React.createElement("input", { id: day, onClick: () => {
                            const dayIndex = form.values.daysOff.indexOf(day);
                            dayIndex !== -1 ? remove(dayIndex) : push(day);
                        }, name: "daysOff", type: "checkbox", value: day }),
                    React.createElement("label", { className: "is-checkbox radio radio-before", htmlFor: day },
                        React.createElement("strong", null, capitalize(day))));
            }))))));
}
