import { selectErrors, selectPending } from 'common';
import { Button } from 'components/Form';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createAddressAttempt, deleteAddressAttempt, updateAddressAttempt } from 'store/account/addresses/addressActions';
import "./Adresses.scss";
import { AddressForm } from 'components/AddressForm/AddressForm';
import { selectAddresses, selectAddressUpdated } from 'store/account/profile/profileSelectors';
import useStep from 'shared/hooks/useStep';
import { Angle } from 'components/Angle/Angle';
import { Formik } from 'formik';
import { CheckMarkAnimation } from 'components/Animations/CheckMarkAnimation';
const initialValues = {
    name: '',
    firstname: '',
    lastname: '',
    salon: '',
    address: '',
    country: '',
    city: '',
    postalCode: '',
    phone: '',
    phoneFix: ''
};
function Adresses() {
    const dispatch = useDispatch();
    const [activeStep, setActiveStep] = useStep();
    const addressUpdated = useSelector(selectAddressUpdated);
    const apiErrors = useSelector(activeStep === ''
        ? selectErrors('CREATE_ADDRESS')
        : selectErrors('UPDATE_ADDRESS'));
    const addresses = useSelector(selectAddresses);
    const pending = useSelector(selectPending('UPDATE_ADDRESS'));
    return (React.createElement("div", { className: "profile" },
        React.createElement("div", { className: "white-block" },
            React.createElement("div", { className: "adresses container" },
                React.createElement("strong", { className: "ec-account--content_sub" }, "Liste de vos adresses et cr\u00E9ation de nouvelle adresse."),
                React.createElement("div", { className: `ec-address ${activeStep === '' ? 'open' : ''}` },
                    React.createElement("div", { className: "ec-address--header", onClick: () => setActiveStep('') },
                        React.createElement("strong", null, "Ajouter une adresse"),
                        React.createElement(Angle, { isActive: activeStep === '', setActiveStep: setActiveStep, step: '' })),
                    React.createElement("div", { className: "ec-address--form" },
                        React.createElement(Formik, { validateOnChange: true, initialValues: initialValues, enableReinitialize: true, onSubmit: async (values, { resetForm }) => {
                                dispatch(createAddressAttempt(values, (err, res) => {
                                    if (res) {
                                        setActiveStep(res._id);
                                        resetForm({ values: initialValues });
                                    }
                                }));
                            } },
                            React.createElement(AddressForm, { apiErrors: apiErrors },
                                React.createElement("div", { className: "ec-address--form_actions" },
                                    React.createElement(Button, { buttonName: "Enregistrer", className: "ec-button ec-button--secondary ec-button--br ec-button--small" })))))),
                addresses.length > 0 && addresses.map((address) => {
                    return React.createElement("div", { key: address._id, className: `ec-address adresse-item ${activeStep === address._id ? 'open' : ''}` },
                        React.createElement("div", { onClick: () => setActiveStep(address._id), className: "ec-address--header adresse-toggle" },
                            React.createElement("strong", null, address.name),
                            React.createElement(Angle, { isActive: activeStep === address._id, setActiveStep: setActiveStep, step: address._id })),
                        React.createElement("div", { className: "ec-address--form adresse-infos" },
                            React.createElement(Formik, { validateOnChange: true, initialValues: address, enableReinitialize: true, onSubmit: async (values) => dispatch(updateAddressAttempt(values)) },
                                React.createElement(AddressForm, { apiErrors: apiErrors },
                                    React.createElement("div", { className: "ec-address--form_actions" },
                                        React.createElement(Button, { type: "button", className: "ec-nudebutton ec-nudebutton--secondary", onClick: () => dispatch(deleteAddressAttempt(address._id)), buttonName: "Supprimer" }),
                                        React.createElement(Button, { loading: pending, buttonName: "Modifier", className: "ec-button ec-button--secondary ec-button--br ec-button--small" }))))));
                }))),
        React.createElement(CheckMarkAnimation, { message: "La modification de votre adresse a bien \u00E9t\u00E9 enregistr\u00E9e.", isActive: addressUpdated })));
}
export default Adresses;
