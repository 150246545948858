import { Summary } from "components/Cart/Summary";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectCart } from "store/cart/cartSelectors";
import { AddressQuotation } from "./components/AddressQuotation";
function Quotation() {
    const [quotationAddress, setQuotationAddress] = useState({
        quotationAddress: null,
    });
    const setQuotationValue = (name, value) => {
        setQuotationAddress((prev) => (Object.assign(Object.assign({}, prev), { [name]: value })));
    };
    const cart = useSelector(selectCart);
    return (React.createElement("div", { className: "cart container" },
        React.createElement(Helmet, null,
            React.createElement("title", null, "Devis - KUST | La Marque des Coiffeurs pas Comme Les Autres")),
        React.createElement("div", { className: "columns is-multiline is-mobile is-centered order-tunnel" },
            React.createElement(Link, { to: "/panier", className: "go-back is-half" },
                React.createElement("img", { src: "/assets/step-arrow.svg" }),
                " Retour au panier"),
            React.createElement("div", { className: "column is-half-desktop is-full-mobile" },
                React.createElement("div", { className: "content" },
                    React.createElement(AddressQuotation, { quotationValues: quotationAddress, setQuotationValue: setQuotationValue }))),
            React.createElement("div", { className: "column is-one-quarter black-block" },
                React.createElement(Summary, { title: "Mon r\u00E9capitulatif", cart: cart })))));
}
export default Quotation;
