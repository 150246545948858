import Img from "components/Img/Img";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductsAttempt } from "store/products/productActions";
import { selectProductsWithLeaves } from "store/products/productSelectors";
import { isFreeChoicesStockValid } from "common";
export function ProductIncludedItem({ included }) {
    const dispatch = useDispatch();
    const products = useSelector(selectProductsWithLeaves);
    useEffect(() => {
        dispatch(fetchProductsAttempt());
    }, [dispatch]);
    return (React.createElement("div", { className: "choice-item" },
        included.imageUrl instanceof Array
            ? React.createElement(Img, { src: `${included.imageUrl[0]}`, width: 100, height: 100, name: `${included.imageUrl[0]}` })
            : React.createElement(Img, { src: `${included.imageUrl}`, width: 100, height: 100, name: `${included.imageUrl}` }),
        React.createElement("div", null,
            React.createElement("span", null, included.name)),
        React.createElement("span", null, !isFreeChoicesStockValid(included, products) && 'Reliquat')));
}
