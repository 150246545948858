import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrdersAttempt } from "store/account/orders/ordersActions";
import { selectOrders } from "store/account/orders/ordersSelectors";
function Factures() {
    const dispatch = useDispatch();
    const orders = useSelector(selectOrders);
    useEffect(() => {
        dispatch(fetchOrdersAttempt());
    }, [dispatch]);
    return (React.createElement("div", { className: "profile" },
        React.createElement("div", { className: "white-block" },
            React.createElement("div", { className: "commandes" },
                React.createElement("strong", { className: "ec-account--content_sub" }, "Historique de vos factures."),
                React.createElement("div", { className: "orders-list" }, orders.length > 0 && orders.reverse().map((order) => {
                    var _a, _b;
                    return React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "ec-account--content_sub ec-account--order" }, order.createdAt),
                        React.createElement("div", { key: order.orderId, className: "ec-block" },
                            React.createElement("div", { className: "ec-block--header", style: { 'width': '100%' } },
                                "Commande n\u00B0",
                                order.orderId),
                            ((_a = order === null || order === void 0 ? void 0 : order.billing) === null || _a === void 0 ? void 0 : _a.viewUrl) !== undefined ? React.createElement("div", { className: "ec-block--content" },
                                React.createElement("span", null, "\u21B3"),
                                " ",
                                React.createElement("a", { href: (_b = order === null || order === void 0 ? void 0 : order.billing) === null || _b === void 0 ? void 0 : _b.viewUrl, target: "_blank" }, " T\u00E9l\u00E9charger ma facture")) : ''));
                }))))));
}
export default Factures;
