import React from "react";
import './Carousel.scss';
const Carousel = ({ children }) => {
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const activeSlide = children.map((slide, index) => (React.createElement("div", { "data-active": currentSlide === index, className: `ec-carousel--slide ${currentSlide === index ? 'active' : ''}`, key: index }, slide)));
    const previousSlide = children.map((slide, index) => (React.createElement("div", { "data-active": currentSlide === index + 1, className: `ec-carousel--slide ${currentSlide === index + 1 ? 'active' : ''}`, key: index + 1 }, slide)));
    const handleClickprev = () => {
        setCurrentSlide((currentSlide - 1 + activeSlide.length) % activeSlide.length);
    };
    const handleClicknext = () => {
        setCurrentSlide((currentSlide + 1) % activeSlide.length);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "ec-carousel" },
            React.createElement("div", { className: "ec-oval" },
                React.createElement("div", { className: "ec-oval--container" },
                    React.createElement("div", { className: "ec-carousel--slider", style: { transform: `translateX(-${currentSlide * 100}%)` } }, activeSlide))),
            React.createElement("div", { className: "ec-oval ec-oval--prev" },
                React.createElement("div", { className: "ec-oval--container" },
                    React.createElement("div", { className: "ec-carousel--slider", style: { transform: `translateX(-${(currentSlide - 1) * 100}%)` } }, previousSlide))),
            React.createElement("div", { className: "ec-carousel--nav" },
                React.createElement("button", { className: "ec-carousel--nav_left", onClick: handleClickprev }, "\u00A0"),
                React.createElement("button", { className: "ec-carousel--nav_right", onClick: handleClicknext }, "\u00A0")))));
};
export default Carousel;
