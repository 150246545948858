import { choicesLeft, choicesScaleLeft, ProductType } from "common";
import React from "react";
export function ChoicesLeft({ product }) {
    const isChoicesOverflow = () => {
        if (product.type === ProductType.CHOICE_PRODUCT && !product.restricted) {
            return false;
        }
        if (product.type === ProductType.SCALE_GROUP) {
            return choicesScaleLeft(product) < 0;
        }
        return choicesLeft(product) < 0;
    };
    return (React.createElement("div", { className: "choices-left" },
        choicesLeft(product) > 0 && product.type !== ProductType.SCALE_GROUP && (React.createElement("p", null,
            choicesLeft(product),
            " nuances \u00E0 choisir !")),
        choicesScaleLeft(product) > 0 && product.type === ProductType.SCALE_GROUP && (React.createElement("p", null,
            "Jusqu'\u00E0 ",
            choicesScaleLeft(product),
            " nuances \u00E0 choisir !")),
        isChoicesOverflow() && (React.createElement("p", null, "Vous avez s\u00E9lectionn\u00E9 trop de nuances !"))));
}
