import React from 'react';
import { Helmet } from 'react-helmet';
import './Maintenance.scss';
export function Maintenance() {
    return (React.createElement("div", { className: "maintenance-body" },
        React.createElement(Helmet, null,
            React.createElement("title", null, "Kust - Maintenance")),
        React.createElement("article", { className: "maintenance-article" },
            React.createElement("h1", { className: "maintenance-h1" }, "Une op\u00E9ration de maintenance est en cours ..."),
            React.createElement("div", null,
                React.createElement("p", null, "\u2014 Votre \u00E9quipe KUST")))));
}
