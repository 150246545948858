var _a;
import { Days, isBrowser } from "common";
import { Modal } from "components/Modal/Modal";
import React from "react";
import useModal from "shared/hooks/useModal";
import GoogleMapReact from 'google-map-react';
import Img from "components/Img/Img";
import { Button } from "components/Form";
import { Field } from "formik";
import { capitalize } from "shared/helpers";
const PudoCursor = ({}) => React.createElement(Img, { src: "/assets/pickup_marker.png", name: "pudo" });
const RAZZLE_GOOGLE_MAP_KEY = isBrowser()
    ? `${(_a = window.__env__) === null || _a === void 0 ? void 0 : _a.RAZZLE_GOOGLE_MAP_KEY}`
    : `${process.env.RAZZLE_GOOGLE_MAP_KEY}`;
export function PudoListItem({ pudo }) {
    const { toggleModal } = useModal();
    const getDayByDayId = (dayId) => {
        return Object.values(Days)[dayId];
    };
    return (React.createElement("div", { className: "pudo-item" },
        React.createElement("label", { className: "radio radio-before" },
            React.createElement("span", { className: "radio__input" },
                React.createElement(Field, { name: "pudo" }, ({ form }) => (React.createElement("input", { name: "pudo", type: "radio", onClick: () => {
                        form.setFieldValue('pudo', pudo);
                    } }))),
                React.createElement("span", { className: "radio__control" })),
            React.createElement("strong", null, pudo.name)),
        React.createElement("div", { className: "pudo-infos" },
            React.createElement("div", null,
                React.createElement("div", null, pudo.address1),
                React.createElement("div", null, pudo.city),
                React.createElement("div", null, pudo.zipCode),
                React.createElement("div", null,
                    (+pudo.distance / 1000).toFixed(2),
                    " km")),
            React.createElement(Button, { type: "button", onClick: () => toggleModal(pudo._id), buttonName: "+ de d\u00E9tails" })),
        React.createElement(Modal, { id: pudo._id },
            React.createElement("div", { className: "pudo-modal" },
                React.createElement("div", { style: { height: '300px', width: '100%' } },
                    React.createElement(GoogleMapReact, { bootstrapURLKeys: { key: RAZZLE_GOOGLE_MAP_KEY }, defaultCenter: {
                            lat: parseFloat(pudo.latitude),
                            lng: parseFloat(pudo.longitude)
                        }, defaultZoom: 18 },
                        React.createElement(PudoCursor, { lat: parseFloat(pudo.latitude), lng: parseFloat(pudo.longitude) }))),
                React.createElement("table", null,
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("th", { colSpan: 2 }, "Horaires d'ouverture"))),
                    React.createElement("tbody", null, Object.values(Days).map((_, index) => {
                        const hours = pudo.openingHoursItems.filter((item) => +item.dayId === index + 1);
                        return React.createElement("tr", { key: index },
                            React.createElement("td", null, capitalize(getDayByDayId(index))),
                            hours.length === 0
                                ? React.createElement("td", null, "Ferm\u00E9")
                                : React.createElement("td", null,
                                    hours[0].start,
                                    "-",
                                    hours[0].end,
                                    " ",
                                    `${hours[1] ? ` / ${hours[1].start}-${hours[1].end}` : ''}`));
                    })))))));
}
