import { PaymentMode, selectErrors, UserRights } from "common";
import { InputError } from "components/Form";
import React from "react";
import { useSelector } from "react-redux";
import { selectProfileInfos } from "store/account/profile/profileSelectors";
export default function OrderMode({ setOrderMode, setCgu }) {
    const profile = useSelector(selectProfileInfos);
    const apiErrors = useSelector(selectErrors('ORDER'));
    return (React.createElement("div", { className: "ec-form" },
        React.createElement("div", { className: "ec-radio" },
            React.createElement("input", { name: "paymentMode", value: PaymentMode.CB, type: "radio", id: "CB", onClick: () => setOrderMode(PaymentMode.CB) }),
            React.createElement("label", { className: "radio radio-before", htmlFor: "CB" },
                React.createElement("strong", { className: "ec-cart--step_radio" }, "Paiement par Carte bancaire"))),
        (profile === null || profile === void 0 ? void 0 : profile.type) === UserRights.PREMIUM &&
            React.createElement("div", { className: "ec-radio" },
                React.createElement("input", { name: "paymentMode", value: PaymentMode.TRANSFER, type: "radio", id: "virement", onClick: () => setOrderMode(PaymentMode.TRANSFER) }),
                React.createElement("label", { className: "radio radio-before", htmlFor: "virement" },
                    React.createElement("strong", { className: "ec-cart--step_radio" }, "Paiement par virement bancaire"))),
        React.createElement("div", { className: "cgv" },
            React.createElement("div", { className: "ec-radio ec-check" },
                React.createElement("input", { type: "checkbox", className: "checkbox", id: "cgv", onChange: setCgu }),
                React.createElement("label", { className: "is-checkbox radio radio-before", htmlFor: "cgv" },
                    React.createElement("strong", { className: "ec-cart--step_radio" },
                        "En cochant cette case, je reconnais avoir pris connaissance des ",
                        React.createElement("a", { href: "/pages/cgv", target: "_blank" }, "conditions g\u00E9n\u00E9rales de ventes"),
                        ".")))),
        React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.message })));
}
