import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchHomeAttempt } from "store/home/homeActions";
import { selectHome } from "store/home/homeSelectors";
import "./Home.scss";
import { Link } from "react-router-dom";
import ProductListItem from "containers/Products/components/ProductListItem";
import { selectRealisations } from "store/realisations/realisationsSelectors";
import { fetchRealisationsAttempt } from "store/realisations/realisationsActions";
import { Helmet } from "react-helmet";
function Home() {
    var _a;
    const dispatch = useDispatch();
    const home = useSelector(selectHome);
    const realisations = useSelector(selectRealisations);
    useEffect(() => {
        var _a, _b, _c;
        dispatch(fetchHomeAttempt());
        dispatch(fetchRealisationsAttempt());
        try {
            if (typeof ((_a = window) === null || _a === void 0 ? void 0 : _a.yotpo) !== "undefined") {
                (_c = (_b = window) === null || _b === void 0 ? void 0 : _b.yotpo) === null || _c === void 0 ? void 0 : _c.initWidgets();
            }
        }
        catch (err) {
            console.log('initWidget - error', err);
        }
    }, [dispatch]);
    return (React.createElement("div", { className: "home is-fullhd container ec-background" },
        React.createElement(Helmet, null,
            React.createElement("title", null, "KUST | La Marque des Coiffeurs pas Comme Les Autres")),
        React.createElement("section", { className: "ec-herobanner" },
            React.createElement("div", { className: "ec-herobanner--container" },
                React.createElement("div", { className: "ec-herobanner--data" },
                    React.createElement("h1", null,
                        "Prise en ",
                        React.createElement("b", null, "main"),
                        " Parfaite"),
                    React.createElement("strong", null, "Le pinceau con\u00E7u pour et par les artistes coloristes."),
                    React.createElement("div", { className: "ec-herobanner--data_action" },
                        React.createElement(Link, { to: '/', className: "ec-button ec-button--bl" }, "Commander"))),
                React.createElement("figure", { className: "ec-herobanner--visual" },
                    React.createElement("img", { src: "../assets/pinceau--loyance.png", alt: "pinceau loyance pour coiffeurs coloristes" }))),
            React.createElement("ul", { className: "ec-bullets" },
                React.createElement("li", null,
                    React.createElement("strong", null, "Ergonomie"),
                    "Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilis\u00E9e \u00E0 titre provisoire."),
                React.createElement("li", null,
                    React.createElement("strong", null, "Durabilit\u00E9"),
                    "Texte d\u00E9finitif venant remplacer le faux-texte d\u00E8s qu'il est pr\u00EAt ou que la mise en page."),
                React.createElement("li", null,
                    React.createElement("strong", null, "Technologie"),
                    "Il est pr\u00EAt ou que la mise en page est achev\u00E9e. On utilise un texte en faux latin."))),
        React.createElement("section", { className: "ec-homeproducts" },
            React.createElement("div", { className: "ec-homeproducts--container" },
                React.createElement("h1", null, "Outils pour artistes coloristes"),
                React.createElement("div", { className: "ec-grid" }, (_a = home.products) === null || _a === void 0 ? void 0 : _a.map((product) => React.createElement("div", { className: "ec-grid--item", key: product === null || product === void 0 ? void 0 : product._id },
                    React.createElement(ProductListItem, { path: `/nos-produits/${product.slug}`, product: product }))))),
            React.createElement("svg", { className: "svgclip" },
                React.createElement("clipPath", { id: "svgclip-section", clipPathUnits: "objectBoundingBox" },
                    React.createElement("path", { d: "M0,0.028 L0.5,0.001 C0.5,0.001,0.5,0.001,0.5,0.001 L1,0.028 L1,0.028 L1,0.973 L0.5,1 C0.5,1,0.5,1,0.5,1 L0,0.973 L0,0.973 L0,0.028" }))),
            React.createElement("svg", { className: "svgclip" },
                React.createElement("clipPath", { id: "svgclip-section--xs", clipPathUnits: "objectBoundingBox" },
                    React.createElement("path", { d: "M0,0.009 L0.487,0 C0.487,0,0.487,0,0.487,0 L1,0.009 L1,0.009 L1,0.991 L0.487,1 C0.487,1,0.487,1,0.487,1 L0,0.991 L0,0.991 L0,0.009" })))),
        React.createElement("section", { className: "ec-homesocial" },
            React.createElement("figure", null,
                React.createElement("img", { src: "../assets/communaute-loyance-instagram.webp", alt: "communaut\u00E9 loyance sur instagram" })),
            React.createElement(Link, { to: "/" },
                React.createElement("div", { className: "ec-homesocial--data" },
                    React.createElement("img", { src: "../assets/icone--instagram.svg", alt: "instagram loyance" }),
                    React.createElement("h1", null, "Communaut\u00E9"),
                    React.createElement("strong", null, "Loyance & vous"))))));
}
export default Home;
