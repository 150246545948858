import { OrderActionTypes } from "./orderTypes";
const initialState = {
    order: null,
    formToken: '',
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case OrderActionTypes.ORDER_SUCCESS:
            return Object.assign(Object.assign({}, state), action.payload);
        default:
            return state;
    }
};
export { reducer as orderReducer };
