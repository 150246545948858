import { Angle } from "components/Angle/Angle";
import React from "react";
export function AddressItem({ activeStep, selectAddress, name, address }) {
    const isActive = activeStep === address._id;
    return (React.createElement("div", { className: `billing-item ${isActive ? 'open' : ''}` },
        React.createElement("div", { className: "billing-toggle" },
            React.createElement("div", { className: "ec-radio" },
                React.createElement("input", { id: `${name}-${address._id}`, checked: isActive, type: "radio", name: name, onChange: () => selectAddress(address) }),
                React.createElement("label", { className: "radio radio-before", htmlFor: `${name}-${address._id}` },
                    React.createElement("span", { className: "ec-cart--step_radio" }, address.name))),
            React.createElement(Angle, { disabled: true, isActive: isActive, step: address._id })),
        React.createElement("div", { className: "billing-infos" },
            React.createElement("div", null,
                React.createElement("div", null, address.salon),
                React.createElement("div", null,
                    address.lastname,
                    " ",
                    address.firstname),
                React.createElement("div", null, address.address),
                React.createElement("div", null,
                    address.postalCode,
                    " ",
                    address.city,
                    ", ",
                    address.country),
                React.createElement("div", null, address.phone),
                React.createElement("div", null, address.phoneFix)))));
}
