import { Button } from "components/Form";
import { push } from "connected-react-router";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./OrderFailure.scss";
import { Helmet } from "react-helmet";
import { selectCart } from "store/cart/cartSelectors";
export function OrderFailure() {
    const dispatch = useDispatch();
    const cart = useSelector(selectCart);
    useEffect(() => {
        if (!cart)
            dispatch(push('/'));
    }, [cart]);
    return (React.createElement("div", { className: "modal-backdrop" },
        React.createElement("div", { className: "modal-container" },
            React.createElement(Helmet, null,
                React.createElement("title", null, "KUST | La Marque des Coiffeurs pas Comme Les Autres")),
            React.createElement("div", { className: "column is-half-desktop is-full-mobile" },
                React.createElement("h1", null, "OH NON!"),
                React.createElement("h2", null, "Une erreur s'est produite, la commande n'est pas pass\u00E9e."),
                React.createElement(Link, { to: "/commande", className: "mt-5 is-flex is-justify-content-flex-start" },
                    React.createElement(Button, { type: "button", buttonName: "Essayer \u00E0 nouveau", className: "ec-button ec-button--b ec-modalbutton" }))))));
}
