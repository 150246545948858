import { ShippingModes, convertToEuros } from "common";
import { Field } from "formik";
import React from "react";
const SHIPPING_MODES = [
    {
        mode: ShippingModes.CLASSIC,
        description: 'Livraison en salon 24 à 48H'
    },
    {
        mode: ShippingModes.PREDICT,
        description: 'Livraison à domicile 24 à 48H'
    },
    {
        mode: ShippingModes.PICKUP,
        description: 'Livraison en point relais'
    },
];
export function ShippingMode({ fees }) {
    return (React.createElement("div", null,
        React.createElement("span", { className: "ec-cart--step_sub" }, "1.S\u00E9lectionnez un mode de livraison selon vos pr\u00E9f\u00E9rences :"),
        React.createElement(Field, { name: "mode" }, ({ form }) => (React.createElement("div", { className: "ec-form" }, SHIPPING_MODES.map((value) => (React.createElement("div", { key: value.mode, className: "delivery-mode" }, fees[value.mode] !== null && React.createElement("div", { className: "ec-radio" },
            React.createElement("input", { id: value.mode, name: "shipping_mode", type: "radio", onClick: () => form.setFieldValue('mode', value.mode) }),
            React.createElement("label", { className: "radio radio-before", htmlFor: value.mode },
                React.createElement("span", { className: "ec-cart--step_radio" },
                    value.description,
                    " ",
                    convertToEuros(fees[value.mode]),
                    "\u20AC")))))))))));
}
