import React, { useEffect } from "react";
import "./Register.scss";
import { useDispatch, useSelector } from "react-redux";
import { Input, Select, InputError, Button } from "components/Form";
import { selectAnswersAsSimpleArray } from "store/answers/answerSelectors";
import { fetchAnswersAttempt } from "store/answers/answerActions";
import { selectErrors, Countries, getCountryByCode } from "common";
import { Formik, Form } from "formik";
import { registerProfileAttempt } from "store/account/profile/profileActions";
import { removeEmptyString } from "shared/helpers";
import Socials from "containers/Socials/Socials";
import { selectSocials } from "store/account/profile/profileSelectors";
import { BusinessType } from "common";
function Register() {
    const dispatch = useDispatch();
    const apiErrors = useSelector(selectErrors('REGISTER_PROFILE'));
    const socials = useSelector(selectSocials);
    const answers = useSelector(selectAnswersAsSimpleArray);
    useEffect(() => {
        dispatch(fetchAnswersAttempt());
    }, []);
    const onSubmit = (credentials) => {
        dispatch(registerProfileAttempt(removeEmptyString(credentials)));
    };
    return (React.createElement("div", { className: "register" },
        React.createElement("h1", null, "Nouveau client"),
        React.createElement("p", null, "Inscrivez-vous avec les r\u00E9seaux ou de mani\u00E8re classique avec les champs :"),
        React.createElement(Socials, null),
        React.createElement(Formik, { validateOnChange: true, initialValues: {
                lastname: !!socials ? socials.lastname : '',
                firstname: !!socials ? socials.firstname : '',
                salon: '',
                email: !!socials ? socials.email : '',
                email_confirmation: !!socials ? socials.email : '',
                password: '',
                password_confirmation: '',
                country: Countries.FRANCE,
                siret: '',
                intraCommunityVATNumber: '',
                answers: [],
                businessType: BusinessType.B2B
            }, enableReinitialize: true, onSubmit: onSubmit }, ({ values }) => (React.createElement(Form, { className: "ec-form" },
            React.createElement(Input, { name: "firstname", label: "Pr\u00E9nom", color: "grey" }),
            React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.firstname }),
            React.createElement(Input, { name: "lastname", label: "Nom", color: "grey" }),
            React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.lastname }),
            React.createElement(Input, { name: "salon", label: "Nom du salon", color: "grey" }),
            React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.salon }),
            React.createElement(Input, { name: "email", label: "E-mail", color: "grey" }),
            React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.email }),
            React.createElement(Input, { name: "email_confirmation", label: "Confirmation E-mail", color: "grey" }),
            React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.email_confirmation }),
            React.createElement(Input, { name: "password", type: "password", label: "Mot de passe", color: "grey" }),
            React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.password }),
            React.createElement(Input, { name: "password_confirmation", type: "password", label: "Confirmation du mot de passe", color: "grey" }),
            React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.password_confirmation }),
            React.createElement(Select, { label: "Pays", defaultLabel: "Veuillez s\u00E9lectionner un pays", name: "country", displayKey: "name", valueKey: "value", options: Object.values(Countries)
                    .map(country => {
                    return { name: getCountryByCode(country).toUpperCase(), value: country };
                }) }),
            React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.country }),
            values.country === Countries.FRANCE ?
                React.createElement(React.Fragment, null,
                    React.createElement(Input, { name: "siret", label: "Num\u00E9ro de siret", color: "grey" }),
                    React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.siret }))
                :
                    React.createElement(React.Fragment, null,
                        React.createElement(Input, { name: "intraCommunityVATNumber", label: "Num\u00E9ro de TVA intracommunautaire (Si votre num\u00E9ro est valide vous serez automatiquement exon\u00E9r\u00E9 de TVA)", color: "grey" }),
                        React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.intraCommunityVATNumber })),
            React.createElement(Select, { label: "O\u00F9 avez-vous connu la marque KUST ?", defaultLabel: "Veuillez s\u00E9lectionner une r\u00E9ponse", valueKey: "label", displayKey: "label", name: "answers", options: answers }),
            React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.question }),
            React.createElement(Button, { buttonName: "Enregistrer", className: "ec-button ec-button--br" }))))));
}
export default Register;
