import React from "react";
import "./Login.scss";
import { useDispatch, useSelector } from 'react-redux';
import { Input, Button, InputError } from "components/Form";
import { Link } from "react-router-dom";
import { loginAttempt, selectErrors } from "common";
import { Formik, Form } from "formik";
import useModal from "shared/hooks/useModal";
import Socials from "containers/Socials/Socials";
function Login({ modalId }) {
    const dispatch = useDispatch();
    const { isModalOpen, closeModal } = useModal();
    const errors = useSelector(selectErrors('LOGIN'));
    const onSubmit = (credentials) => {
        dispatch(loginAttempt(credentials));
        closeModal(modalId);
    };
    return (React.createElement("div", { className: "login has-text-centered" },
        React.createElement("h1", null, "D\u00E9j\u00E0 client"),
        React.createElement("p", null, "Connectez-vous avec les r\u00E9seaux ou de mani\u00E8re classique avec les champs :"),
        React.createElement(Socials, null),
        React.createElement(Formik, { validateOnChange: true, initialValues: {
                email: '',
                password: '',
                remember_me: false
            }, enableReinitialize: true, onSubmit: onSubmit },
            React.createElement(Form, { className: "ec-form" },
                React.createElement(Input, { name: "email", label: "E-mail", color: "white" }),
                React.createElement(Input, { name: "password", type: "password", label: "Mot de passe", color: "white" }),
                React.createElement(Button, { className: "ec-button ec-button--bl", type: "submit", buttonName: "Connexion" }),
                React.createElement(InputError, { apiErrors: errors === null || errors === void 0 ? void 0 : errors.apiGenericError }),
                React.createElement(Link, { to: "/recuperation" }, "Mot de passe oubli\u00E9 ?")))));
}
/*
    A revoir, ce n'est pas dans le design
                <!--<label>Se souvenir de moi</label>
                <Input type="checkbox" name="remember_me" />-->

*/
export default Login;
