import React, { useState } from "react";
import "./Header.scss";
import { Link, useLocation, } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCartProducts, selectProductAddedToCart } from "store/cart/cartSelectors";
import { selectIsAuthenticated } from "common";
import Banner from "containers/Banner/Banner";
import { selectBanner } from "store/banner/bannerSelectors";
function Header() {
    const productAddedToCart = useSelector(selectProductAddedToCart);
    const cartProducts = useSelector(selectCartProducts);
    const isAuthenticated = useSelector(selectIsAuthenticated());
    const [menuOpen, setMenuOpen] = useState(false);
    const [kustCommunityOpen, setKustCommunityOpen] = useState(false);
    const [nomadAcademyOpen, setNomadAcademyOpen] = useState(false);
    const { pathname } = useLocation();
    const banner = useSelector(selectBanner);
    const getHeaderClasses = () => {
        const open = menuOpen ? 'open' : '';
        return (pathname === '/' || kustCommunityOpen || nomadAcademyOpen) ? `black-background ${open}` : open;
    };
    const getKustCommunityClass = () => {
        return 'header-page ' + (kustCommunityOpen ? 'header-page-open' : '');
    };
    const getNomadAcademyClass = () => {
        return 'header-page ' + (nomadAcademyOpen ? 'header-page-open' : '');
    };
    const location = useLocation();
    React.useEffect(() => {
        setKustCommunityOpen(false);
        setNomadAcademyOpen(false);
    }, [location]);
    return (React.createElement("header", { className: `ec-header` },
        React.createElement(Banner, null),
        React.createElement("nav", { className: "ec-navbar ec-navbar--desktop only-desktop navbar", role: "navigation", "aria-label": "main navigation" },
            React.createElement("ul", { className: "ec-navbar--desktop_menu" },
                React.createElement("li", null,
                    React.createElement(Link, { className: "ec-navbar--link", to: "/", "aria-label": "loyance" }, "Loyance")),
                React.createElement("li", null,
                    React.createElement(Link, { className: "ec-navbar--link", to: "/nos-produits/pinceaux", "aria-label": "Pinceaux" }, "Pinceaux")),
                React.createElement("li", null,
                    React.createElement(Link, { className: "ec-navbar--link", to: "/nos-produits/accessoires", "aria-label": "Accessoires" }, "Accessoires")),
                React.createElement("li", null,
                    React.createElement(Link, { className: "ec-navbar--logo", to: "/" },
                        React.createElement("img", { src: "/assets/logo-loyance--blanc.svg", alt: "logo loyance blanc" }))),
                React.createElement("li", null,
                    React.createElement(Link, { className: "ec-navbar--link", to: "/nos-produits/consommables", "aria-label": "Consommables" }, "Consommables")),
                React.createElement("li", { onClick: () => setMenuOpen(false), className: `${isAuthenticated ? 'account-icon-authenticated' : ''}` },
                    React.createElement(Link, { to: isAuthenticated ? '/mon-compte/profile' : '/authentification', className: "ec-navbar--link", "aria-label": "Compte" }, "Compte")),
                React.createElement("li", { onClick: () => setMenuOpen(false) },
                    React.createElement(Link, { to: "/panier", className: `ec-navbar--link ${productAddedToCart ? 'add-to-cart-animation' : ''}`, suppressHydrationWarning: true, "aria-label": "Panier" },
                        "Panier",
                        cartProducts.length > 0 &&
                            React.createElement("div", { className: "ec-navbar--qty" }, cartProducts.reduce((quantity, product) => { return quantity + product.quantity; }, 0)))))),
        React.createElement("nav", { className: `ec-navbar ec-navbar--mobile only-mobile ${getHeaderClasses()}` },
            React.createElement("ul", { className: "ec-navbar--mobile_bar" },
                React.createElement("li", null,
                    React.createElement("a", { onClick: () => setMenuOpen(!menuOpen), role: "button", className: "ec-navbar--menu", "aria-label": "menu", "aria-expanded": "false", "data-target": "navigation" })),
                React.createElement("li", null,
                    React.createElement(Link, { to: "/" },
                        React.createElement("img", { src: "/assets/logo-loyance-type--blanc.svg", alt: "logo texte loyance blanc" }))),
                React.createElement("li", { onClick: () => setMenuOpen(false) },
                    React.createElement(Link, { to: "/panier", className: `ec-navbar--cart ${productAddedToCart ? 'add-to-cart-animation' : ''}`, suppressHydrationWarning: true }, cartProducts.length > 0 &&
                        React.createElement("div", { className: "ec-navbar--qty" }, cartProducts.reduce((quantity, product) => { return quantity + product.quantity; }, 0))))),
            React.createElement("ul", { className: "ec-navbar--mobile_menu", role: "navigation", "aria-label": "main navigation" },
                React.createElement("li", null,
                    React.createElement(Link, { onClick: () => setMenuOpen(false), className: "ec-navbar--link", to: "/" }, "Loyance")),
                React.createElement("li", null,
                    React.createElement(Link, { onClick: () => setMenuOpen(false), className: "ec-navbar--link", to: "/nos-produits/pinceaux" }, "Pinceaux")),
                React.createElement("li", null,
                    React.createElement(Link, { onClick: () => setMenuOpen(false), className: "ec-navbar--link", to: "/nos-produits/accessoires" }, "Accessoires")),
                React.createElement("li", null,
                    React.createElement(Link, { onClick: () => setMenuOpen(false), className: "ec-navbar--link", to: "/nos-produits/consommables" }, "Consommables")),
                React.createElement("li", { onClick: () => setMenuOpen(false), className: `${isAuthenticated ? 'account-icon-authenticated' : ''}` },
                    React.createElement(Link, { to: isAuthenticated ? '/mon-compte/profile' : '/authentification', className: "ec-navbar--link", "aria-label": "Compte" }, "Compte"))))));
}
export default Header;
