import React from "react";
import "./CartInfos.scss";
import { Summary } from "./Summary";
import { useDispatch } from "react-redux";
import { removePromotionFromCart } from "store/cart/cartActions";
export function CartInfos({ cart, children }) {
    const dispatch = useDispatch();
    return (React.createElement("div", { className: "ec-cart--summary" },
        React.createElement(Summary, { title: "Mon panier", cart: cart, removePromotion: () => dispatch(removePromotionFromCart()) }, children)));
}
