import { haveFreeProducts, isCartProductValid, isStockValid } from "common";
import { Button } from "components/Form";
import React from "react";
export function AddToCartButton({ product, toggleModal, isAuthenticated, editMode }) {
    const getButton = () => {
        if (!isAuthenticated) {
            return React.createElement(Button, { buttonName: "Connexion", className: "ec-button ec-button--bl", type: "button", onClick: () => toggleModal() });
        }
        return React.createElement(Button, Object.assign({}, (haveFreeProducts(product) ? {
            onClick: () => toggleModal(),
            type: "button"
        } : {
            type: "submit"
        }), { buttonName: !isStockValid(product)
                ? "Produit indisponible"
                : editMode ? "Modifier le produit" : "Commander", className: "ec-button ec-button--br", disabled: !isCartProductValid(product, {}) })); //TODO
    };
    return (React.createElement("div", null, getButton()));
}
