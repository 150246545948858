import { convertToEuros, getChoicesQuantity, logoutAttempt, ProductType } from "common";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useStep from "shared/hooks/useStep";
import { fetchQuotationsAttempt } from "store/account/quotations/quotationsActions";
import { selectQuotations } from "store/account/quotations/quotationsSelectors";
import { addToCart } from "store/cart/cartActions";
import { Angle } from "components/Angle/Angle";
import { Button } from "components/Form";
import { CheckMarkAnimation } from "components/Animations/CheckMarkAnimation";
import { selectProductAddedToCart } from "store/cart/cartSelectors";
function Devis() {
    const dispatch = useDispatch();
    const [activeStep, setActiveStep] = useStep();
    const quotations = useSelector(selectQuotations);
    const productAddedToCart = useSelector(selectProductAddedToCart);
    const loadCart = (products) => {
        for (const product of products) {
            if (!product.expired) {
                dispatch(addToCart(product));
            }
        }
    };
    const isReusableOrder = (products) => {
        return products.filter(product => product.expired).length === products.length;
    };
    useEffect(() => {
        dispatch(fetchQuotationsAttempt());
    }, [dispatch]);
    return (React.createElement("div", { className: "profile" },
        React.createElement("div", { className: "before-white" },
            React.createElement("div", null,
                React.createElement("h1", null, "Vos devis"),
                React.createElement("p", null, "Bienvenue dans votre espace personnel KUST !")),
            React.createElement(Button, { buttonName: "D\u00E9connexion", onClick: () => dispatch(logoutAttempt()), className: "black-white" })),
        React.createElement("div", { className: "white-block" },
            React.createElement("div", { className: "commandes" },
                React.createElement("strong", null, "Historique de mes devis."),
                React.createElement("div", { className: "orders-list" }, quotations.length > 0 && quotations.map((quotation) => {
                    var _a;
                    return React.createElement("div", { key: quotation.quotationId, className: `order-item ${activeStep === quotation.quotationId ? 'open' : ''}` },
                        React.createElement("div", { onClick: () => setActiveStep(quotation.quotationId), className: "order-toggle" },
                            quotation.createdAt,
                            " - ",
                            quotation.quotationId),
                        React.createElement("div", { className: "order-divider" },
                            React.createElement(Angle, { isActive: activeStep === quotation.quotationId, setActiveStep: setActiveStep, step: quotation.quotationId })),
                        React.createElement("div", { className: "order-content" },
                            quotation.products.length
                                ? React.createElement("div", { className: "column is-mobile" },
                                    React.createElement("div", { className: "order-list" },
                                        quotation.products.map((product, index) => {
                                            return (React.createElement("div", { className: "order-list-item", key: index },
                                                React.createElement("span", { className: product.expired ? 'expired' : '' },
                                                    product.name,
                                                    " x ",
                                                    product.quantity),
                                                React.createElement("span", null,
                                                    (convertToEuros(product.price
                                                        * (product.type === ProductType.CHOICE_PRODUCT && !product.restricted || product.type === ProductType.SCALE_GROUP ? getChoicesQuantity(product) : 1)
                                                        * product.quantity)),
                                                    "\u20AC")));
                                        }),
                                        React.createElement("div", { className: "order-list-item" },
                                            React.createElement("span", null,
                                                React.createElement("strong", null, "Total")),
                                            React.createElement("span", null,
                                                React.createElement("strong", null,
                                                    convertToEuros(quotation.total),
                                                    "\u20AC HT")))))
                                : React.createElement("div", null, "Aucun devis pour le moment"),
                            React.createElement("div", { className: "order-content-item columns bill" },
                                React.createElement("div", { className: "column is-full" },
                                    ((_a = quotation === null || quotation === void 0 ? void 0 : quotation.quotation) === null || _a === void 0 ? void 0 : _a.viewUrl) !== undefined
                                        ?
                                            React.createElement("a", { href: quotation.quotation.viewUrl, target: "_blank" },
                                                React.createElement(Button, { className: "black-white", buttonName: "T\u00E9l\u00E9charger mon devis" }))
                                        : '',
                                    React.createElement(Button, { className: "orange reuse-cart-button", buttonName: "Replacer dans le panier", disabled: isReusableOrder(quotation.products), onClick: () => { loadCart(quotation.products); } })))));
                })))),
        React.createElement(CheckMarkAnimation, { message: "Produit(s) ajout\u00E9(s) \u00E0 votre panier !", isActive: productAddedToCart })));
}
export default Devis;
