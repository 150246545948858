import { isChoiceProduct, isGroupProduct, ProductType, isCartProductValid, haveIncludedProducts, isCartFreeProductsValid, selectIsAuthenticated, freeChoicesLeft, isStockValid } from "common";
import React, { useEffect, useRef, useState } from "react";
import { Form, FieldArray, useFormikContext } from "formik";
import { AddToCartButton } from "../components/AddToCartButton";
import { Modal } from "components/Modal/Modal";
import { ProductFreeChoices } from "../components/ProductFreeChoices";
import { ProductQuantity } from "../components/ProductQuantity";
import { ProductDetailFooter } from "../components/ProductDetailFooter";
import { ProductPackSelector } from "../components/ProductPackSelector";
import { ProductChoicesSelector } from "../components/ProductChoicesSelector";
import { Button } from "components/Form";
import { ProductsIncluded } from "../components/ProductsIncluded";
import { isMobile } from 'react-device-detect';
import { useSelector } from "react-redux";
import Login from "containers/Login/Login";
import { Link } from "react-router-dom";
import { selectProductIndexToModify } from "store/cart/cartSelectors";
import { Angle } from "components/Angle/Angle";
export const ProductForm = ({ product, toggleModal, modalId, setReviewId }) => {
    const { values, submitForm } = useFormikContext();
    const isAuthenticated = useSelector(selectIsAuthenticated());
    const ref = useRef(null);
    const [AuthModal] = useState('authModal');
    const productIndexToModify = useSelector(selectProductIndexToModify);
    useEffect(() => {
        var _a;
        setReviewId(((_a = values.element) === null || _a === void 0 ? void 0 : _a.reviewId) || product.reviewId);
    }, [values]);
    const scrollToChoices = () => {
        if ((values === null || values === void 0 ? void 0 : values.type) === ProductType.CHOICE_GROUP || ProductType.SCALE_GROUP && isMobile) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        }
    };
    return (React.createElement(Form, null,
        React.createElement("div", { className: "stepers ec-product--action" },
            isAuthenticated &&
                React.createElement(React.Fragment, null,
                    isGroupProduct(values) &&
                        React.createElement("div", { className: "packer" },
                            React.createElement(ProductPackSelector, { onChange: scrollToChoices, product: product }),
                            React.createElement(Angle, { step: '0', isActive: true })),
                    product.type === ProductType.SIMPLE_PRODUCT && React.createElement(ProductQuantity, null),
                    product.tag &&
                        React.createElement(ProductDetailFooter, { editMode: typeof productIndexToModify === 'number', toggleModal: () => toggleModal(!isAuthenticated ? AuthModal : modalId), isAuthenticated: isAuthenticated, product: values })),
            !isAuthenticated &&
                React.createElement("div", { className: "ec-product--connect" },
                    product.tag &&
                        React.createElement(AddToCartButton, { product: values, toggleModal: () => toggleModal(AuthModal), isAuthenticated: isAuthenticated }),
                    React.createElement("p", null, "Pour consulter les tarifs, vous devez \u00EAtre un.e professionnel.le enregistr\u00E9.e puis connect\u00E9.e."))),
        React.createElement(Modal, { id: modalId },
            React.createElement("div", { className: "included-products" },
                React.createElement("strong", null, "Mes produits offerts"),
                React.createElement("div", { className: "included-details" },
                    React.createElement("p", null, "Veuillez choisir vos produits offerts."),
                    freeChoicesLeft(values) > 0 && (React.createElement("span", null,
                        freeChoicesLeft(values),
                        " restant(s)"))),
                React.createElement("div", { className: "included-list" },
                    React.createElement(FieldArray, { name: isGroupProduct(values) ? 'element.freeChoices.ids' : 'freeChoices.ids' }, ({ replace, form }) => (React.createElement(ProductFreeChoices, { form: form, replace: replace })))),
                product.tag &&
                    React.createElement(Button, { onClick: submitForm, buttonName: !isStockValid(values) ? 'Produit indisponible' : (typeof productIndexToModify === 'number' ? "Modifier le produit" : "Ajouter au panier"), className: "orange cart", disabled: !(isCartProductValid(values, {}) && isCartFreeProductsValid(values)) }) // TODO
            ),
            React.createElement("div", { className: "free-products" },
                React.createElement("p", null, "Produits inclus dans le pack"),
                haveIncludedProducts(values) && React.createElement(ProductsIncluded, { product: values }))),
        React.createElement(Modal, { id: AuthModal },
            React.createElement("div", { className: "included-products login-modal" },
                React.createElement(Login, { modalId: AuthModal }),
                React.createElement("hr", null),
                React.createElement(Link, { to: "/authentification", className: "ec-link--2" }, "Cr\u00E9er un compte"))),
        React.createElement("div", { ref: ref }, isChoiceProduct(values) && React.createElement(ProductChoicesSelector, { toggleModal: () => toggleModal(AuthModal), AuthModal: AuthModal, isAuthenticated: isAuthenticated }))));
};
