import { AddressForm } from "components/AddressForm/AddressForm";
import { Angle } from "components/Angle/Angle";
import { Button } from "components/Form";
import { Formik } from "formik";
import React from "react";
export function CreateAddress({ selectAddress, errors, name, activeStep, pending, createAddress }) {
    return (React.createElement("div", { className: "billings" },
        React.createElement("div", { className: `billing-item ${activeStep === '' ? 'open' : ''}` },
            React.createElement("div", { className: "billing-toggle" },
                React.createElement("div", null,
                    React.createElement("label", { className: "radio radio-before", htmlFor: `new-${name}` },
                        React.createElement("span", { className: "radio__input" },
                            React.createElement("input", { id: `new-${name}`, type: "radio", name: name, onClick: () => selectAddress(null) }),
                            React.createElement("span", { className: "radio__control" })),
                        React.createElement("strong", null, "Nouvelle adresse"))),
                React.createElement(Angle, { disabled: true, isActive: activeStep === '', step: '' })),
            React.createElement("div", { className: "billing-infos" },
                React.createElement(Formik, { initialValues: {}, enableReinitialize: true, onSubmit: createAddress },
                    React.createElement(AddressForm, { apiErrors: errors },
                        React.createElement("div", { className: "adresse-actions" },
                            React.createElement(Button, { loading: pending, buttonName: "Ajouter", className: "black-white" }))))))));
}
