import { Summary } from "components/Cart/Summary";
import React, { createContext, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectCart } from "store/cart/cartSelectors";
import { OrderSteps } from "./OrderSteps";
import { isMobile } from "react-device-detect";
export const OrderContext = createContext({});
function Order() {
    const ref = useRef(null);
    const [order, setOrder] = useState({
        billingAddress: null,
        shippingAddress: null,
        shippingInfos: null,
        paymentMode: null
    });
    const setOrderValue = (name, value) => {
        setOrder((prev) => (Object.assign(Object.assign({}, prev), { [name]: value })));
    };
    const cart = useSelector(selectCart);
    return (React.createElement(OrderContext.Provider, { value: ref },
        React.createElement("div", { className: "ec-container ec-cart" },
            React.createElement("div", { className: "ec-cart--container" },
                !isMobile &&
                    React.createElement("div", { ref: ref, className: "ec-cart--summary" },
                        React.createElement(Summary, { title: "Mon r\u00E9capitulatif", cart: cart })),
                React.createElement("div", { className: "ec-cart--content" },
                    React.createElement(OrderSteps, { order: order, setOrderValue: setOrderValue }))))));
}
export default Order;
