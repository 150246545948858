import { getChoicesQuantity, getOrdersTraduction, ProductType, convertToEuros } from 'common';
import { Angle } from 'components/Angle/Angle';
import { CheckMarkAnimation } from 'components/Animations/CheckMarkAnimation';
import { Button } from 'components/Form';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStep from 'shared/hooks/useStep';
import { fetchOrdersAttempt } from 'store/account/orders/ordersActions';
import { selectOrders } from 'store/account/orders/ordersSelectors';
import { addToCart } from 'store/cart/cartActions';
import { selectProductAddedToCart } from 'store/cart/cartSelectors';
import "./Commandes.scss";
function Commandes() {
    const dispatch = useDispatch();
    const [activeStep, setActiveStep] = useStep();
    const orders = useSelector(selectOrders);
    const productAddedToCart = useSelector(selectProductAddedToCart);
    const loadCart = (products) => {
        for (const product of products) {
            if (!product.expired) {
                dispatch(addToCart(product));
            }
        }
    };
    const isReusableOrder = (products) => {
        return products.filter(product => product.expired).length === products.length;
    };
    useEffect(() => {
        dispatch(fetchOrdersAttempt());
    }, [dispatch]);
    return (React.createElement("div", { className: "profile" },
        React.createElement("div", { className: "white-block" },
            React.createElement("div", { className: "commandes" },
                React.createElement("strong", { className: "ec-account--content_sub" }, "Historique de mes commandes et factures."),
                React.createElement("div", { className: "orders-list" }, orders.length > 0 && orders.map((order) => {
                    var _a;
                    return React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "ec-account--content_sub ec-account--order" }, order.createdAt),
                        React.createElement("div", { key: order.orderId, className: `ec-block ${activeStep === order.orderId ? 'open' : ''}` },
                            React.createElement("div", { onClick: () => setActiveStep(order.orderId), className: "ec-block--header" },
                                React.createElement("strong", null,
                                    "Commande N\u00B0",
                                    order.orderId,
                                    " : ",
                                    getOrdersTraduction(order.status)),
                                React.createElement(Angle, { isActive: activeStep === order.orderId, setActiveStep: setActiveStep, step: order.orderId })),
                            React.createElement("div", { className: "ec-block--content" },
                                order.products.length
                                    ? React.createElement("div", { className: "column is-mobile" },
                                        React.createElement("div", { className: "order-list" },
                                            order.products.map((product, index) => {
                                                return (React.createElement("div", { className: "ec-table--item order-list-item", key: index },
                                                    React.createElement("span", { className: product.expired ? 'expired' : '' },
                                                        product.name,
                                                        " x ",
                                                        product.quantity),
                                                    React.createElement("span", null,
                                                        (convertToEuros(product.price
                                                            * (product.type === ProductType.CHOICE_PRODUCT && !product.restricted || product.type === ProductType.SCALE_GROUP ? getChoicesQuantity(product) : 1)
                                                            * product.quantity)),
                                                        "\u20AC")));
                                            }),
                                            React.createElement("div", { className: "ec-table--item order-list-item" },
                                                React.createElement("span", null,
                                                    React.createElement("strong", null, "Total")),
                                                React.createElement("span", null,
                                                    React.createElement("strong", null,
                                                        convertToEuros(order.total),
                                                        "\u20AC HT")))))
                                    : React.createElement("div", null, "Aucune commande pass\u00E9e pour le moment"),
                                React.createElement("div", { className: "order-content-item columns bill" },
                                    React.createElement("div", { className: "ec-block--content_actions" },
                                        ((_a = order === null || order === void 0 ? void 0 : order.billing) === null || _a === void 0 ? void 0 : _a.viewUrl) !== undefined
                                            ?
                                                React.createElement("a", { href: order.billing.viewUrl, target: "_blank" },
                                                    React.createElement(Button, { className: "ec-nudebutton ec-nudebutton--secondary", buttonName: "T\u00E9l\u00E9charger ma facture" }))
                                            : '',
                                        React.createElement(Button, { className: "ec-button ec-button--br ec-button--small ec-button--secondary", buttonName: "Replacer dans le panier", disabled: isReusableOrder(order.products), onClick: () => { loadCart(order.products); } }))))));
                })))),
        React.createElement(CheckMarkAnimation, { message: "Produit(s) ajout\u00E9(s) \u00E0 votre panier !", isActive: productAddedToCart })));
}
export default Commandes;
