var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Field } from "formik";
import React from "react";
import "./Input.scss";
function Input(_a) {
    var { type = 'text', name, label, color, rules } = _a, rest = __rest(_a, ["type", "name", "label", "color", "rules"]);
    return (React.createElement(Field, { name: name }, ({ form, field }) => (React.createElement("div", Object.assign({ className: "ec-input" }, rest),
        React.createElement("label", { htmlFor: name }, label),
        React.createElement("input", Object.assign({ onChange: (e) => {
                form.setFieldValue(name, e.target.value);
            }, 
            // defaultValue={field.value}
            type: type, value: field.value, name: name, id: name, placeholder: label }, rest))))));
}
export default Input;
