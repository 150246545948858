import { logoutAttempt } from 'common';
import { Button } from 'components/Form';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVideosAttempt } from 'store/account/videos/videoActions';
import { selectVideos } from 'store/account/videos/videoSelector';
import "./Videos.scss";
function Videos() {
    const dispatch = useDispatch();
    const videos = useSelector(selectVideos);
    useEffect(() => {
        dispatch(fetchVideosAttempt());
    }, [dispatch]);
    return (React.createElement("div", { className: "profile" },
        React.createElement("div", { className: "before-white" },
            React.createElement("div", null,
                React.createElement("h1", null, "Vos vid\u00E9os"),
                React.createElement("p", null, "Bienvenue dans votre espace personnel KUST !")),
            React.createElement(Button, { buttonName: "D\u00E9connexion", onClick: () => dispatch(logoutAttempt()), className: "black-white" })),
        React.createElement("div", { className: "white-block" },
            React.createElement("div", { className: "videos container" }, (videos === null || videos === void 0 ? void 0 : videos.length) > 0 &&
                React.createElement("div", null, videos.map((video) => React.createElement("div", { className: "video-item", key: video === null || video === void 0 ? void 0 : video._id },
                    React.createElement("video", { controls: true },
                        React.createElement("source", { src: video.link, type: "video/mp4" }),
                        React.createElement("source", { src: video.link, type: "video/mov" })),
                    React.createElement("div", { className: "video-infos" },
                        React.createElement("div", null,
                            React.createElement("strong", null, video.name),
                            React.createElement("div", { dangerouslySetInnerHTML: { __html: video.description } })),
                        React.createElement("span", null,
                            video.duration,
                            " min")))))),
            (videos === null || videos === void 0 ? void 0 : videos.length) === 0 &&
                React.createElement("div", null, "Il n'y a pas de vid\u00E9os disponibles pour le moment."))));
}
export default Videos;
