import { ShippingModes } from "common";
import { Form, Formik } from "formik";
import React from "react";
import { StepButton } from "./components/StepButton";
import { Step } from "./components/Step";
import { Steps } from "./OrderSteps";
import { ShippingsForm } from "./ShippingsForm";
const isShippingStepValid = (values) => {
    if (!(values === null || values === void 0 ? void 0 : values.mode) || (values.mode === ShippingModes.PICKUP && !values.pudo))
        return false;
    return true;
};
export function ShippingsStep({ orderValues, setOrderValue, activeOrderStep, setActiveOrderStep }) {
    const submit = (shippingInfos) => setOrderValue('shippingInfos', shippingInfos);
    return (React.createElement("div", { className: "ec-cart--step" },
        React.createElement(Step, { step: Steps.SHIPPING_MODE_STEP, disabled: !orderValues.shippingAddress, activeStep: activeOrderStep, setActiveStep: setActiveOrderStep, title: "Mode de livraison" },
            React.createElement("div", null,
                React.createElement(Formik, { validateOnChange: true, enableReinitialize: true, onReset: () => submit(null), initialValues: {
                        mode: null,
                        daysOff: [],
                        note: '',
                        pudo: null
                    }, onSubmit: submit }, ({ submitForm, values }) => (React.createElement(Form, null,
                    React.createElement(ShippingsForm, { shippingAddress: orderValues.shippingAddress }),
                    activeOrderStep === Steps.SHIPPING_MODE_STEP &&
                        React.createElement(StepButton, { loading: false, disabled: !isShippingStepValid(values), onClick: async () => {
                                await submitForm();
                                setActiveOrderStep(Steps.PAYMENT_STEP);
                            } }))))))));
}
