import React from "react";
import useStep from "shared/hooks/useStep";
import { AddressesSteps } from "./AddressesSteps";
import { PaymentStep } from "./PaymentStep";
import { ShippingsStep } from "./ShippingsStep";
import "./OrderSteps.scss";
import { Helmet } from "react-helmet";
export var Steps;
(function (Steps) {
    Steps[Steps["BILLING_ADDRESS_STEP"] = 1] = "BILLING_ADDRESS_STEP";
    Steps[Steps["SHIPPING_ADDRESS_STEP"] = 2] = "SHIPPING_ADDRESS_STEP";
    Steps[Steps["SHIPPING_MODE_STEP"] = 3] = "SHIPPING_MODE_STEP";
    Steps[Steps["PAYMENT_STEP"] = 4] = "PAYMENT_STEP";
})(Steps || (Steps = {}));
export function OrderSteps({ order, setOrderValue }) {
    const [activeOrderStep, setActiveOrderStep] = useStep(Steps.BILLING_ADDRESS_STEP);
    return (React.createElement("div", { className: "order-steps ec-cart--steps" },
        React.createElement(Helmet, null,
            React.createElement("title", null, "Commander - KUST | La Marque des Coiffeurs pas Comme Les Autres")),
        React.createElement(AddressesSteps, { orderValues: order, setOrderValue: setOrderValue, activeOrderStep: activeOrderStep, setActiveOrderStep: setActiveOrderStep }),
        React.createElement(ShippingsStep, { orderValues: order, setOrderValue: setOrderValue, activeOrderStep: activeOrderStep, setActiveOrderStep: setActiveOrderStep }),
        React.createElement(PaymentStep, { orderValues: order, setOrderValue: setOrderValue, activeOrderStep: activeOrderStep, setActiveOrderStep: setActiveOrderStep })));
}
