export const reduceCartProduct = (product) => {
    var _a, _b, _c;
    return {
        _id: product === null || product === void 0 ? void 0 : product._id,
        choices: (_a = product === null || product === void 0 ? void 0 : product.choices) === null || _a === void 0 ? void 0 : _a.filter(choice => !!choice.quantity),
        element: product === null || product === void 0 ? void 0 : product.element,
        price: product.price ? product.price : (_b = product.element) === null || _b === void 0 ? void 0 : _b.price,
        priceVat: product.priceVat ? product.priceVat : (_c = product.element) === null || _c === void 0 ? void 0 : _c.priceVat,
        type: product === null || product === void 0 ? void 0 : product.type,
        code: product === null || product === void 0 ? void 0 : product.code,
        name: product === null || product === void 0 ? void 0 : product.name,
        imageUrl: product === null || product === void 0 ? void 0 : product.imageUrl,
        slug: product === null || product === void 0 ? void 0 : product.slug,
        restricted: product === null || product === void 0 ? void 0 : product.restricted,
        quantity: product === null || product === void 0 ? void 0 : product.quantity,
        freeChoices: product === null || product === void 0 ? void 0 : product.freeChoices,
        freeIncluded: product === null || product === void 0 ? void 0 : product.freeIncluded,
        weight: product === null || product === void 0 ? void 0 : product.weight,
        stock: product === null || product === void 0 ? void 0 : product.stock,
        businessType: product.businessType
    };
};
export const elementPriceCartProduct = (product) => {
    var _a, _b;
    return Object.assign(Object.assign({}, product), { price: product.price ? product.price : (_a = product.element) === null || _a === void 0 ? void 0 : _a.price, priceVat: product.priceVat ? product.priceVat : (_b = product.element) === null || _b === void 0 ? void 0 : _b.priceVat });
};
