import { Decrement, Increment } from "components/Quantity";
import { useFormikContext } from "formik";
import React from "react";
import { isStockValid } from "common";
import "./ProductQuantity.scss";
export function ProductQuantity() {
    const { values, setFieldValue } = useFormikContext();
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "ec-product--action_qty" },
            React.createElement(Decrement, { decrement: () => (values.quantity > 0) && setFieldValue('quantity', +values.quantity - 1) }),
            React.createElement("input", { className: "quantity", name: "quantity", defaultValue: values.quantity || 0, value: values.quantity, min: 0, onChange: (e) => {
                    setFieldValue('quantity', e.target.value);
                } }),
            React.createElement(Increment, { increment: () => setFieldValue('quantity', +values.quantity + 1) })),
        !isStockValid(values) && React.createElement("p", null, "La quantit\u00E9 choisie est sup\u00E9rieure au stock du produit")));
}
