import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { fetchContentAttempt } from "store/contents/contentsActions";
import { selectContent } from "store/contents/contentsSelectors";
import "./Contents.scss";
import Img from 'components/Img/Img';
import { Helmet } from 'react-helmet';
function Content() {
    var _a;
    const { slug } = useParams();
    const dispatch = useDispatch();
    const content = useSelector(selectContent(slug));
    useEffect(() => {
        dispatch(fetchContentAttempt(slug));
    }, [dispatch, slug]);
    return (React.createElement("div", { className: "ec-content" }, !!content && (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, content.title),
            React.createElement("meta", { name: "description", content: content.metaDescription }),
            React.createElement("meta", { name: "keywords", content: content.metaKeywords })),
        React.createElement("div", { className: "ec-content--visual" },
            React.createElement("div", null,
                (content === null || content === void 0 ? void 0 : content.file) &&
                    React.createElement("div", { className: "content-image-container", style: { 'backgroundImage': `url(${content.file})` } },
                        React.createElement("div", { className: "content-image-window", style: { 'backgroundImage': `url(${content.file})` } },
                            React.createElement("div", { className: "content-image-content" },
                                React.createElement("h1", null, content.title),
                                React.createElement("h2", null, content.subtitle)))),
                React.createElement("svg", { className: "svgclip" },
                    React.createElement("clipPath", { id: "svgclip-section", clipPathUnits: "objectBoundingBox" },
                        React.createElement("path", { d: "M0,0.028 L0.5,0.001 C0.5,0.001,0.5,0.001,0.5,0.001 L1,0.028 L1,0.028 L1,0.973 L0.5,1 C0.5,1,0.5,1,0.5,1 L0,0.973 L0,0.973 L0,0.028" }))),
                React.createElement("svg", { className: "svgclip" },
                    React.createElement("clipPath", { id: "svgclip-section--xs", clipPathUnits: "objectBoundingBox" },
                        React.createElement("path", { d: "M0,0.009 L0.487,0 C0.487,0,0.487,0,0.487,0 L1,0.009 L1,0.009 L1,0.991 L0.487,1 C0.487,1,0.487,1,0.487,1 L0,0.991 L0,0.991 L0,0.009" }))))),
        React.createElement("div", { className: "ec-content--body" },
            !content.file && React.createElement(React.Fragment, null,
                React.createElement("h1", null, content.title),
                React.createElement("h2", null, content.subtitle)),
            React.createElement("div", null,
                !!content.introTitle &&
                    React.createElement("div", { className: "" },
                        React.createElement("div", { className: "" },
                            React.createElement("h3", null, content.introTitle),
                            React.createElement("div", { dangerouslySetInnerHTML: { __html: content.introContent } }))), (_a = content.content) === null || _a === void 0 ? void 0 :
                _a.map((contentBlock, i) => React.createElement("div", { className: "", key: contentBlock === null || contentBlock === void 0 ? void 0 : contentBlock._id },
                    React.createElement("div", { className: "" },
                        React.createElement("h3", null, contentBlock.title),
                        React.createElement("div", { dangerouslySetInnerHTML: { __html: contentBlock.content } })),
                    contentBlock.file !== undefined
                        ? React.createElement("div", { className: "" },
                            React.createElement(Img, { src: `${contentBlock === null || contentBlock === void 0 ? void 0 : contentBlock.file}`, name: `${contentBlock.title}` }))
                        : null))),
            !!content.buttonLink && React.createElement(Link, { to: content.buttonLink },
                React.createElement("button", { className: "ec-button ec-button--b" }, content.buttonTitle)))))));
}
export default Content;
