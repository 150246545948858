import { Countries, getTotalPriceByCountry, convertToEuros, convertFromVatToHT } from "common";
import React from "react";
import { useSelector } from "react-redux";
import { selectProfileInfos } from "store/account/profile/profileSelectors";
import './Summary.scss';
import { Link } from "react-router-dom";
export function Summary({ title, children, cart, removePromotion }) {
    const user = useSelector(selectProfileInfos);
    return (React.createElement("div", { className: "ec-summary" },
        React.createElement("header", { className: "ec-summary--header" },
            React.createElement(Link, { to: "/nos-produits", className: "ec-backlink" }, "<"),
            React.createElement("strong", null, title)),
        React.createElement("table", { className: "ec-summary--table" },
            !!cart.promotion && cart.products.length > 0 &&
                React.createElement("tr", null,
                    React.createElement("td", null,
                        "Remise ",
                        cart.promotion.title),
                    React.createElement("td", null,
                        "- ",
                        convertToEuros(cart.promoReduction),
                        "\u20AC"),
                    typeof removePromotion === 'function' && React.createElement("td", null,
                        React.createElement("span", { onClick: removePromotion }, "x"))),
            typeof cart.fees === 'number' &&
                React.createElement("tr", null,
                    React.createElement("td", null, "Frais de livraison"),
                    cart.fees > 0 ?
                        React.createElement("td", null,
                            convertToEuros(convertFromVatToHT(cart.fees)),
                            "\u20AC")
                        :
                            React.createElement("td", { style: { textAlign: "right" } },
                                "OFFERTS",
                                React.createElement("br", null),
                                React.createElement("small", null, "(min. achat 200\u20AC)"))),
            React.createElement("tr", null,
                React.createElement("td", null, "Total H.T."),
                React.createElement("td", null,
                    convertToEuros(cart.totalWithPromo + (convertFromVatToHT(cart.fees || 0))),
                    "\u20AC")),
            (user === null || user === void 0 ? void 0 : user.country) === Countries.FRANCE
                ?
                    React.createElement(React.Fragment, null,
                        React.createElement("tr", null,
                            React.createElement("td", null, "TVA"),
                            React.createElement("td", null,
                                convertToEuros(getTotalPriceByCountry(cart, user.country) - (cart.totalWithPromo + (convertFromVatToHT(cart.fees || 0)))),
                                "\u20AC")),
                        React.createElement("tr", null,
                            React.createElement("td", null, "Total T.T.C."),
                            React.createElement("td", null,
                                convertToEuros(getTotalPriceByCountry(cart, user.country)),
                                "\u20AC")))
                :
                    React.createElement(React.Fragment, null,
                        React.createElement("tr", null,
                            React.createElement("td", null, "Total H.T."),
                            React.createElement("td", null,
                                convertToEuros(getTotalPriceByCountry(cart, user === null || user === void 0 ? void 0 : user.country)),
                                "\u20AC")))),
        React.createElement("div", { className: "ec-summary--actions" }, children)));
}
