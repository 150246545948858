import { AddressForm } from "components/AddressForm/AddressForm";
import { Angle } from "components/Angle/Angle";
import { Button } from "components/Form";
import { Formik } from "formik";
import React from "react";
export function CreateAddress({ selectAddress, activeStep, name, errors, pending, createAddress }) {
    return (React.createElement("div", { className: "billings" },
        React.createElement("div", { className: `billing-item ${activeStep === '' ? 'open' : ''}` },
            React.createElement("div", { className: "billing-toggle" },
                React.createElement("div", { className: "ec-radio" },
                    React.createElement("input", { id: `new-${name}`, type: "radio", name: name, onClick: () => selectAddress(null) }),
                    React.createElement("label", { className: "radio radio-before ec-radio", htmlFor: `new-${name}` },
                        React.createElement("span", { className: "ec-cart--step_radio" }, "Nouvelle adresse"))),
                React.createElement(Angle, { disabled: true, isActive: activeStep === '', step: '' })),
            React.createElement("div", { className: "billing-infos" },
                React.createElement(Formik, { initialValues: {}, enableReinitialize: true, onSubmit: createAddress },
                    React.createElement(AddressForm, { apiErrors: errors },
                        React.createElement(Button, { loading: pending, buttonName: "Ajouter", className: "ec-button ec-button--small ec-button--tertiary" })))))));
}
