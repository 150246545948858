import { Button } from "components/Form";
import { push } from "connected-react-router";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CartInfos } from "components/Cart/CartInfos";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { selectQuotation } from "store/quotation/quotationSelectors";
export function QuotationSuccess() {
    const dispatch = useDispatch();
    const quotation = useSelector(selectQuotation);
    useEffect(() => {
        if (!quotation)
            dispatch(push('/'));
    }, [quotation]);
    return (React.createElement("div", { className: "cart container order-success" }, quotation &&
        React.createElement("div", { className: "columns is-multiline is-mobile is-centered" },
            React.createElement(Helmet, null,
                React.createElement("title", null, "F\u00E9licitation - KUST | La Marque des Coiffeurs pas Comme Les Autres")),
            React.createElement("div", { className: "column is-half-desktop is-full-mobile" },
                React.createElement("h1", null, "Merci !"),
                React.createElement("h2", null, "Votre devis a bien \u00E9t\u00E9 cr\u00E9\u00E9."),
                React.createElement("p", null,
                    "Nous avons h\u00E2te que votre talent s\u2019exprime avec nos produits\u00A0!",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "La confirmation de devis vous a \u00E9t\u00E9 envoy\u00E9e \u00E0 l\u2019adresse mail indiqu\u00E9e lors de votre enregistrement. Si aucun mail n\u2019appara\u00EEt, pensez \u00E0 v\u00E9rifier dans votre spam\u00A0",
                    React.createElement("span", null, "\u263A"),
                    ".",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "En cas d\u2019incident, merci d\u2019adresser votre requ\u00EAte via ",
                    React.createElement(Link, { to: "/pages/nous-contacter", className: "contact" }, "nous contacter."))),
            React.createElement("div", { className: "column is-one-quarter black-block" },
                React.createElement(CartInfos, { cart: quotation },
                    React.createElement(Link, { to: "/mon-compte/devis" },
                        React.createElement(Button, { type: "button", buttonName: "Mes devis", className: "dark" })))))));
}
