import { Countries, getCountryByCode } from "common";
import { Input, InputError, Select } from "components/Form";
import { Form } from "formik";
import React from "react";
export function AddressForm({ children, apiErrors }) {
    return (React.createElement(Form, { noValidate: true, autoComplete: 'off', className: "ec-form" },
        React.createElement(Input, { name: "name", label: "Nom de l'adresse", className: "ec-input ec-input--dark" }),
        React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.name }),
        React.createElement(Input, { name: "lastname", label: "Nom", className: "ec-input ec-input--dark" }),
        React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.lastname }),
        React.createElement(Input, { name: "firstname", label: "Pr\u00E9nom", className: "ec-input ec-input--dark" }),
        React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.firstname }),
        React.createElement(Input, { name: "salon", label: "Nom du salon", className: "ec-input ec-input--dark" }),
        React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.salon }),
        React.createElement(Input, { name: "address", label: "Adresse", className: "ec-input ec-input--dark" }),
        React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.address }),
        React.createElement(Select, { label: "Pays", defaultLabel: "Je choisis mon pays de livraison", name: "country", displayKey: "name", valueKey: "value", className: "ec-input ec-input--dark", options: Object.values(Countries)
                .map(country => {
                return { name: getCountryByCode(country).toUpperCase(), value: country };
            }) }),
        React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.country }),
        React.createElement(Input, { name: "city", label: "Ville", className: "ec-input ec-input--dark" }),
        React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.city }),
        React.createElement(Input, { name: "postalCode", label: "Code postal", type: "number", className: "ec-input ec-input--dark" }),
        React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.postalCode }),
        React.createElement(Input, { name: "phone", label: "Tel. Mobile", className: "ec-input ec-input--dark" }),
        React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.phone }),
        React.createElement(Input, { name: "phoneFix", label: "Tel. Fixe", className: "ec-input ec-input--dark" }),
        React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.phoneFix }),
        children));
}
